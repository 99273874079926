import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'ngx-get-password',
  templateUrl: './get-password.component.html',
  styleUrls: ['./get-password.component.scss']
})
export class GetPasswordComponent implements OnInit {
  app_id: any;
  docID: any;
  password: any;
  email: any;
  file : any;

  constructor(private route : ActivatedRoute,
              protected api : ApiService) {
    this.app_id = this.route.snapshot.queryParamMap.get('app_id');
    this.docID = this.route.snapshot.queryParamMap.get('docID');
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.file = this.route.snapshot.queryParamMap.get('fileName');
   }

  ngOnInit() {
    //this.api.getIpAddress().subscribe(data => {
      var location = 'NA';// data;
      this.api.getPassword(this.app_id, this.docID , location, this.email,this.file).subscribe((data)=>{
        if(data['status'] == 200){
          this.password = data['password'];
          this.api.downloadStructureFile(this.file,this.app_id)
            .subscribe(structure_File => {
              saveAs(structure_File, this.file);
            });
        }
      });
   //})
  }

}

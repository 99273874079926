import { Component, Input } from '@angular/core';
import { NbDialogRef,NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { RegisteredComponent } from './registered.component';
import { NbToastrService } from '@nebular/theme';

@Component({
    selector: 'nb-dialog',
    template: `
       <nb-card class="col-md-6 offset-md-3" [style.height.px]="600">
        <nb-card-header id="header">Please review and consider the following before continuing: <nb-action icon="ion-close" style="float: right;" (click)="close()"></nb-action></nb-card-header>
           <nb-card-body>
        <nb-alert status="danger" closable (close)="onClose()" *ngIf="messagealertflag == 1">
                Email Already Exists !!!!
        </nb-alert> 
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-11">
                <nb-checkbox status="success" value="true" disabled>        
                    The colleges that I am considering for application may communicate with me by mail, email, phone or text message prior to submission of my application (you can change this response later within your account settings).
                </nb-checkbox>
            </div>
        </div><br>
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-11">
                <nb-checkbox status="success" value="true" disabled>
                The Mumbai University may communicate with me by mail, email, phone or text message about my account and other information relevant to the admission application process (you can change this response later within your account settings).
                </nb-checkbox>
            </div>
        </div><br>
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-11">
                <nb-checkbox status="success"  [(ngModel)]="Condition3">
                        By checking this box, I represent that (i) I am age 13 or older and (ii) I have read, understood and agreed to the terms and conditions of the Terms of Use and Privacy Policy (unless I am over the age of 13 but under the age of 18, in which case, my parent or legal guardian has also read, understood and agreed to the terms and conditions of the Privacy Policy and Terms of Use sections).
                </nb-checkbox>
            </div>
            
        </div><br>
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-11">
                <nb-checkbox status="success" [(ngModel)]="Condition4">
                   I accept that (I) Fees paid is not refundable  And (II) All the information filled by me is true.
                </nb-checkbox>
            </div>
		</div><br>
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-11">
                <nb-checkbox status="success"  [(ngModel)]="Condition5">
                    The Mumbai University website uses small files stored on your computer, known as cookies, that help us remember your settings and ensure the website works properly. By continuing, I am agreeing to The Mumbai University's use of cookies.
                </nb-checkbox>
            </div>
        </div> 	
        <div class="col-lg-11"*ngIf="alertflag == 1" id ="alert" >
            <h5 style="color:red">You should agree All terms & Conditions.</h5>
        </div>
		  
      </nb-card-body>
	  <nb-card-footer>
	  <div class="row">
	  <div class="offset-lg-4"></div><button nbButton [nbSpinner]="loading" nbSpinnerStatus="danger" nbSpinnerSize="xlarge"  status="success"  (click)="Register()" class="col-lg-4">Accept & Register</button>
	  
	 </div>           
        </nb-card-footer>
    </nb-card>
    `,
    })
    export class TermsComponent {
        @Input() userName:string;
        @Input() Surname:string;
        @Input() userPassword:string;
        @Input() Gender:string;
        //@Input() userDob:string;
        @Input() userEmail:string;
        @Input() userCountryCode:string;       
        @Input() userContactNo:string;
        @Input() userCity:string;
        @Input() postal_code:string;
        @Input() Country:string;
        @Input() user_option :string;
        @Input() current_location:string;
        @Input() whatsapp_phoneCode:string;
        @Input() whatsapp_No:string;



        loading=false;
        Condition;
        Condition3;
        Condition4;
        Condition5;
        alertflag=0;
        messagealertflag=0;
        alert;
        user_id: any;
        constructor(protected ref: NbDialogRef<TermsComponent>,
            private router : Router,
            private dialogService: NbDialogService,
            private toasterService: NbToastrService,
            protected api : ApiService) {
        }

        Register(){
            this.Condition=this.Condition3;

            var register_data ={
                userName : this.userName ,
                Surname: this.Surname,
                userPassword : this.userPassword,
                Gender : this.Gender,
               // userDob : this.userDob,
                userEmail : this.userEmail,
                userCountryCode : this.userCountryCode,
                userContactNo : this.userContactNo,
                user_option : this.user_option ,
                current_location : this.current_location,
                whatsapp_phoneCode : this.whatsapp_phoneCode,
                whatsapp_No : this.whatsapp_No,
            }

            if(this.Condition != true || this.Condition4 != true || this.Condition5 != true){
                    this.alertflag=1
            }else{
                this.loading = true;
                this.alertflag=0
                this.api.RegisterValues(register_data)
                    .subscribe(
                    (data: any) => {
                        if(data['status'] == 200){
                            this.messagealertflag = 0;
                            this.user_id=data['data'];
                            this.loading = false;
                            this.ref.close();
                            this.toasterService.success('otp send successfully!!','Success',{duration: 3000});
                            this.dialogService.open(RegisteredComponent, {
                                context: {
                                    email: this.userEmail,
                                    password:this.userPassword,
                                    userCountryCode: this.userCountryCode,
                                    userContactNo : this.userContactNo,
                                    user_id  : this.user_id
                                },
                            });
                        }else if(data['status'] == 400){
                            this.messagealertflag = 1;    
                            this.toasterService.danger('Unable to send otp Please try again','Error',{duration: 3000});                           
                            console.error("error 400 ");
                        }else{
                        }       
                        err => console.error(err)
                    });
            }
        }

        onClose() {
            this.messagealertflag = 0;
            this.ref.close();
        }

        close(){
            this.ref.close();
        }
    }
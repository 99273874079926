import { Component, Input} from '@angular/core';
import { NbDialogRef, NbThemeService } from '@nebular/theme';
import { ApiService } from '../../shared/api.service';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
//import { UserService } from '../../../@core/data/users.service';
import { config } from '../../../../config';
import {ConfirmationService} from 'primeng/api';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import {
  FormGroup, FormBuilder, Validators, FormControl
} from '@angular/forms';
@Component({
selector: 'nb-dialog',
template: `
<nb-card [nbSpinner]="loading" nbSpinnerStatus="danger" nbSpinnerSize="xlarge" size="large">
  <nb-card-header>
    <h5>Add Documents</h5>
  </nb-card-header>
  <nb-card-body>
    <span style="color : red; font-size : 14px;">Please enter document name more then 4 letters, select college and
      then upload transcript</span>
    <br />
    <span style="color : red; font-size : 14px;">Do not use special characters</span>
    <br />
    <br />
    <div class="row">
      <div class="col-xl-6">
        <mat-label><b>Select Document Type</b></mat-label>
      </div>
      <div class="col-xl-6">
        <mat-select (selectionChange)="selectType($event)" placeholder="select document type">
          <mat-option *ngFor="let type of types" [value]="type">
            {{type.viewValue}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-xl-6">
        <mat-label><b>Select degree</b></mat-label>
      </div>
      <div class="col-xl-6">
        <mat-select (selectionChange)="selectDegree($event)" placeholder="select degree level">
          <mat-option *ngFor="let deg of degree" [value]="deg">
            {{deg.viewValue}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <br />
    <br />
    <div class="row"
      *ngIf="this.documentType != 'transcriptNameChange' && this.documentType != 'curriculum' && this.documentType != 'gradeToPercentLetter'">
      <div class="col-xl-6">
        <b>Document Name: </b>

      </div>
      <div class="col-xl-6">
        <input type="text" (input)="onSearchChange($event.target.value)" nbInput [(ngModel)]="transcript_name"
          name='inputTranscriptName' id="inputTranscriptName" placeholder="Transcript Name"
          (keypress)="checkSpcialChar($event)">
      </div>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-xl-6">
        <mat-label><b>Select College</b></mat-label>
      </div>
      <div class="col-xl-6">
        <mat-select (selectionChange)="selectCollege($event)" placeholder="select college">
          <mat-option *ngFor="let education of degreeData" [value]="education">
            {{education.college_name}} - {{education.faculty}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="row"
      *ngIf="showUpload == true && hideUploadMore == true && this.documentType != 'transcriptNameChange' && this.documentType != 'curriculum'">
      <div class="col-xl-4"></div>
      <div class="col-xl-4">
        <br>
        <div class="row" *ngIf="this.documentType == 'marklist' && hideUploadMore == true ">
          <p-fileUpload chooseLabel="Upload" (onSelect)="onSelect($event)" *ngIf="showUpload == true" name="file"
            mode="basic" auto="true" [accept]="'.jpg,.jpeg,.png,.PNG,.JPEG,.JPG,.PDF,.pdf'"
            url="{{serverUrl}}/api/admin/uploadStudentDocument?transcript_name= {{transcript_name}}&user_id={{user_id}}&type={{documentType}}&education_type={{selectedDegree}}&clgId={{college_id}}&app_id={{app_id}}&faculty={{faculty}}"
            maxFileSize="5000000" (onBeforeSend)="onBeforeSend($event)" (onUpload)="onUpload($event)">
          </p-fileUpload>

          <span *ngIf='uploaderror == true' style="color:red; font-size: x-large;">Max uploaded file size should be 5
            MB.</span>
        </div>

      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row"
      *ngIf="showUpload == true && hideUploadMore == true && this.documentType != 'transcriptNameChange' && this.documentType != 'curriculum'">
      <div class="col-xl-4"></div>
      <div class="col-xl-4">
        <br>
        <div class="row" *ngIf="this.documentType == 'transcript' && hideUploadMore == true ">
          <p-fileUpload chooseLabel="Upload" (onSelect)="onSelect($event)" *ngIf="showUpload == true" name="file"
            mode="basic" auto="true" [accept]="'.jpg,.jpeg,.png,.PNG,.JPEG,.JPG,.PDF,.pdf'"
            url="{{serverUrl}}/api/admin/uploadStudentDocument?transcript_name= {{transcript_name}}&user_id={{user_id}}&type={{documentType}}&education_type={{selectedDegree}}&clgId={{college_id}}&app_id={{app_id}}&faculty={{faculty}}"
            maxFileSize="5000000" (onBeforeSend)="onBeforeSend($event)" (onUpload)="onUpload($event)">
          </p-fileUpload>

          <span *ngIf='uploaderror == true' style="color:red; font-size: x-large;">Max uploaded file size should be 5
            MB.</span>
        </div>

      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="row" *ngIf="this.documentType == 'transcriptNameChange' && hideUploadMore == true ">
      <p-fileUpload chooseLabel="Upload" (onSelect)="onSelect($event)" name="file" mode="basic" auto="true"
        [accept]="'.jpg,.jpeg,.png,.PNG,.JPEG,.JPG,.PDF,.pdf'"
        url="{{serverUrl}}/api/admin/uploadStudentDocument?transcript_name= Extra Document 1&user_id={{user_id}}&type=transcript&education_type={{selectedDegree}}&clgId={{college_id}}&app_id={{app_id}}&faculty={{faculty}}"
        maxFileSize="5000000" (onBeforeSend)="onBeforeSend($event)" (onUpload)="onUpload($event)">
      </p-fileUpload>

      <span *ngIf='uploaderror == true' style="color:red; font-size: x-large;">Max uploaded file size should be 5
        MB.</span>
    </div>
    <div class="row" *ngIf="this.documentType == 'curriculum' && hideUploadMore == true ">
      <p-fileUpload chooseLabel="Upload" (onSelect)="onSelect($event)" name="file" mode="basic" auto="true"
        [accept]="'.jpg,.jpeg,.png,.PNG,.JPEG,.JPG,.PDF,.pdf'"
        url="{{serverUrl}}/api/admin/uploadStudentDocument?transcript_name=Curriculum&user_id={{user_id}}&type={{documentType}}&education_type={{selectedDegree}}&clgId={{college_id}}&app_id={{app_id}}&faculty={{faculty}}"
        maxFileSize="5000000" (onBeforeSend)="onBeforeSend($event)" (onUpload)="onUpload($event)">
      </p-fileUpload>

      <span *ngIf='uploaderror == true' style="color:red; font-size: x-large;">Max uploaded file size should be 5
        MB.</span>
    </div>
    <div class="row" *ngIf="this.documentType == 'gradeToPercentLetter' && hideUploadMore == true ">
      <p-fileUpload chooseLabel="Upload" (onSelect)="onSelect($event)" name="file" mode="basic" auto="true"
        [accept]="'.jpg,.jpeg,.png,.PNG,.JPEG,.JPG,.PDF,.pdf'"
        url="{{serverUrl}}/api/admin/uploadStudentDocument?transcript_name=Grade To Percentage Letter&user_id={{user_id}}&type=gradeToPercentLetter&education_type={{selectedDegree}}&clgId={{college_id}}&app_id={{app_id}}&faculty={{faculty}}"
        maxFileSize="5000000" (onBeforeSend)="onBeforeSend($event)" (onUpload)="onUpload($event)">
      </p-fileUpload>

      <span *ngIf='uploaderror == true' style="color:red; font-size: x-large;">Max uploaded file size should be 5
        MB.</span>
    </div>
    <span style="color:red; font-weight: bold; font-sixe:12px;" *ngIf="selectionError != null">{{selectionError}}</span>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="primary" (click)="dismiss()">Close</button>
  </nb-card-footer>
</nb-card>
`,
providers:[ConfirmationService],
})
export class UploadDocuments {
    @Input() user_id : string;
    @Input() app_id : string;
    currenttoken: NbAuthJWTToken;
    loading: boolean;
    uploaderror1: boolean;
    uploaderror: boolean;
    showUpload: boolean;
    college_name: any;
    college_id: any;
    hideUploadMore: boolean = false;
    educationData: any;
    transcript_name : string;
    degree = [
        {value: 'Bachelors', viewValue: 'Bachelors'},
        {value: 'Masters', viewValue: 'Masters'},
        {value: 'Phd', viewValue: 'Phd'},
      ];

    types = [
        {value: 'marklist', viewValue: 'Marksheet'},
        {value: 'transcript', viewValue: 'Transcript'},
        {value: 'transcriptNameChange', viewValue: 'Name Change Proof'},
        {value: 'curriculum', viewValue: 'Curriculum'},
        {value: 'gradeToPercentLetter', viewValue: 'Grade To Percentage Letter'},
    ];
    documentType: any;
    degreeData: any;
    serverUrl = config.serverUrl
    selectedDegree: any;
    faculty: any;
    selectionError: string = null;
    constructor(
    protected ref: NbDialogRef<UploadDocuments>,
    protected api : ApiService,
    private authService: NbAuthService,
    //private userService: UserService,
    public themeService : NbThemeService,
    private confirmationService: ConfirmationService,
    public toasterService: NbToastrService,
    ){}

    dismiss() {
        this.ref.close();
    }

    onBeforeSend(event) {
        this.loading = true;
        this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
        this.currenttoken = token;
            event.xhr.setRequestHeader("Authorization", `Bearer ` +this.currenttoken);
            event.formData.append('token',''+this.currenttoken);
            }
        });
    }
  
    onUpload(event) {
        const reader = new FileReader();
        if(event.files && event.files.length) {
            const [file] = event.files;
            reader.readAsDataURL(file);
            this.loading = false;
            var json = JSON.parse(event.xhr.response);
            var yourData = json.Data; // or json["Data"]
            var yourStatus = json.status; // or json["Data"]
            var yourMessage = json.message;
            this.toasterService.success('Document Added','Success');
            this.ref.close(file);        }
    }
  
    selectedFile: File;

    onSelect($event: any): void {
		var maxFileSize =  5000000;
		var imgArr = $event.files[0].name.split('.');
        var extension = imgArr[imgArr.length - 1].trim();
    
        if(extension!='jpg' && extension!='jpeg' && extension!='png' && extension!='pdf'){
			this.uploaderror1 = true;
        }
    
        if ($event.files[0].size > maxFileSize) {
            this.uploaderror = true;
            this.confirmationService.confirm({
                message: 'Maximum file size should be 5 MB.',
                header: 'Invalid File Size',
                icon: 'pi pi-info-circle',
                rejectVisible : false,
                acceptLabel: 'Ok'
            });
        }
    }


    ngOnInit() {
        this.loading = true;
        this.api.getStudentEducationData(this.user_id, this.app_id).subscribe(data=>{
            this.loading = false;
            this.educationData = data['data'];
                    })
    }

    selectDegree(event){
        
       // this.selectedDegree = event.value;
       
       if(this.documentType == 'marklist'){
            this.selectedDegree = event.value.value
        }else if(this.documentType == 'transcript'){
            this.selectedDegree = 'extra_' + event.value.value + 'transcripts'
        }else if(this.documentType == 'gradeToPercentLetter'){
          this.selectedDegree = event.value.value
        }
        switch(event.value.value){
            case 'Bachelors' : {
                if(this.educationData.bachelors.length > 0){
                    this.degreeData = this.educationData.bachelors;
                    this.selectionError = null;
                }else{
                 this.selectionError = 'No education details available for ' + event.value.value + '. So you cannot select this degree.'
                }
               
                break;
            }
            case 'Masters' : {
                if(this.educationData.masters.length > 0){
                    this.degreeData = this.educationData.masters;
                    this.selectionError = null;
                }else{
                 this.selectionError = 'No education details available for ' + event.value.value + '. So you cannot select this degree.'
                }
                break;
            } 
            case 'Phd' :{
                if(this.educationData.phd.length > 0){
                    this.degreeData = this.educationData.phd;
                    this.selectionError = null;
                }else{
                 this.selectionError = 'No education details available for ' + event.value.value + '. So you cannot select this degree.'
                }
                
                break;
            }

        }
        
    }

    selectType(event){
              this.documentType = event.value.value;

    }

  onSearchChange(searchValue : string ) {
    if(searchValue.length > 3){
      this.showUpload = true;
    }else{
      this.showUpload = false;
    }
  }

  checkSpcialChar(event){
    if(!((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode >= 48) && (event.keyCode <= 57) || event.keyCode == 95)){
       event.returnValue = false;
       return;
    }
    event.returnValue = true;
 }

  // private _filter(value: string){
  //   const filterValue = this._normalizeValue(value);
  
  //   return this.collegeList.filter(option => this._normalizeValue(option.name).includes(filterValue)); 
  // }
  
  // private _normalizeValue(value: string): string {
  //   return value.toLowerCase().replace(/\s/g, '');
  // }
  
  selectCollege(event){
    this.college_name = event.value.college_name;
    this.college_id = event.value.college_id
    this.faculty = event.value.faculty;
    this.hideUploadMore = true
    // this.ngOnInit();
  }
}

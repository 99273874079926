import { Component , Input} from '@angular/core';
import { NbDialogRef} from '@nebular/theme';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';


@Component({
   selector: 'nb-dialog',
  template: `<nb-card [style.width.px]="450"  [style.height.px]="400" status="success">
      <nb-card-header>
        <div class="row">
          <div class="col-md-8">
            Change the caption ({{type}})
          </div>
          <div class="col-md-4" style="text-align:right">
            <nb-action icon="ion-close" (click)="close()"></nb-action>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
            <div class="col-md-4">
                ID :
            </div>
            <div class="col-md-4">
                {{id}}
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-md-4">
                Name :
            </div>
            <div class="col-md-4">
                {{name}}
            </div>
        </div>
        <br/>
        <form [formGroup]="firstForm">
        <div class="row">
        <div class="col-md-4">
                Changed College Name :
            </div>
            <div class="col-md-4">
              <input type="text" aria-label="Number" matInput formControlName="clgId">
            </div>
        </div>
        </form>
        <br/>
      </nb-card-body>
      <nb-card-footer>
        <div class="row" >
          <div class="col-md-5"></div>
          <div class="col-md-7"><button nbButton hero status="primary" (click)="save()">SAVE</button></div>
        </div>           
      </nb-card-footer>
    </nb-card>
    `,
})

export class changeClgNameComponent  {
  @Input() id:any;
  @Input() name:any;
  @Input() type:any;
  changedName : any[]=[];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl('',Validators.required);
  validateUploadOne :boolean=true;
  collegeList:any[]=[];
  firstForm:FormGroup;
  constructor(protected ref: NbDialogRef<changeClgNameComponent>,
    protected api : ApiService,
    private toastrService: NbToastrService,
    private formBuilder:FormBuilder) {
  }

  ngOnInit() { 
    this.firstForm = this.formBuilder.group({
      clgId :['',[Validators.required]]
    })
    // this.api.getCollegeList().subscribe((data)=>{
    //     
    //   if(data['data'] != undefined){
    //     this.filteredOptions = this.myControl.valueChanges.pipe(
    //       startWith(''),
    //       map(value => this._filter(value))
    //     ); 
    //     data['data'].forEach((element: any)=>{
    //       this.collegeList.push( element )
    //     })
    //   }
    // }) 
  }


  private _filter(value: string){
    const filterValue = this._normalizeValue(value);

    return this.collegeList.filter(option => this._normalizeValue(option.name).includes(filterValue));
     
  }
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  save( ){
      this.api.changeCollegeDocumentName(this.id,this.firstForm.controls.clgId.value,this.type).subscribe(data =>{
        if(data['status'] == 200){
            this.toastrService.success("College Name changed successfully","",{duration : 3000});
            this.close();
        }else{
            this.toastrService.danger("College Name not changed","",{duration : 3000});
        }
      })
      }
  
   close(){
    this.ref.close();
  }
}
import { Component, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { FormBuilder, FormGroup,Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { MatStepperModule, MatFormFieldModule } from '@angular/material';

@Component({
selector: 'nb-dialog',
template: `

<nb-card status="success">
    <nb-card-header style="text-align:center;font-size: 150%;">Emailed Doument tracker</nb-card-header>
    <nb-card-body>
      <mat-horizontal-stepper orientation="horizontal" *ngIf="screenWidth > 500" (click)="selectStep();" #progressStepper >
          
        <mat-step   [completed]="deliveredFlag" editable="false">
              <ng-template matStepLabel  matStepperIcon="done">Email Deliverd
             <br>
              {{Mailmessage}}
              </ng-template>
              
          </mat-step>
          <mat-step  [completed]="openedFlag" [completed]="deliveredFlag"  editable="false">
              <ng-template matStepLabel matStepperIcon="done">Email opened
              <br> {{OPENmessage}}
              </ng-template>
             
          </mat-step>
          <mat-step   [completed]="ipFlag" [completed]="openedFlag" [completed]="deliveredFlag"  editable="false">
              <ng-template matStepLabel matStepperIcon="done">Traced IP
              <br> {{IPmsg}}
              </ng-template>
             
          </mat-step>
          
      </mat-horizontal-stepper>
  
      <mat-vertical-stepper orientation="vertical" *ngIf="screenWidth <= 500"  (click)="selectStep();" #progressStepper >
     
        <mat-step   [completed]="deliveredFlag" editable="false">
            <ng-template matStepLabel  matStepperIcon="done">Email Deliverd</ng-template>
            {{Mailmessage}}
        </mat-step>
        <mat-step  [completed]="openedFlag"  editable="false">
            <ng-template matStepLabel matStepperIcon="done">Email opened</ng-template>
            {{OPENmessage}}
        </mat-step>
        <mat-step   [completed]="ipFlag"  editable="false">
            <ng-template matStepLabel matStepperIcon="done">Traced IP</ng-template>
            {{IPmsg}}
        </mat-step>
       
    </mat-vertical-stepper>
      <nb-alert status="danger" closable (close)="onClose()" *ngIf="alertFlag == 1">
        {{message}}
      </nb-alert>
  
      <nb-card-footer>
          <button nbButton (click)="dismiss()">close</button>
      </nb-card-footer>
    </nb-card-body>
  </nb-card> 
  
`,
})
export class applndetails {

 @Input()  email: any;
 @Input() Appc_id: any;
 @Input() user_id: string;
  linearMode = true;
  @ViewChild('progressStepper') stepper;
  message: string;
  alertFlag: number;
  statusflag: number;

  deliveredFlag: boolean;
  openedFlag: boolean;
  ipFlag: boolean;

  isLinear = false;
  stepperFlag : Boolean = false;
 
  screenWidth: number;
  paymentData: any;
    num: number;
    Role: string;
    step: any;
    ip_status: any;
    opens_count: number;
    IPmsg: string;
    OPENmessage: string;
    Mailmessage: string;

  constructor(protected ref: NbDialogRef<applndetails>,
    protected api : ApiService,
    private fb: FormBuilder,
    private authService : NbAuthService,
    private router : Router,
  ) {
    this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
            if(token.getPayload()['role'] =="admin"){
                this.Role = "Admin";
            }
    });
  
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.reset();
  }

  dismiss() {
    this.ref.close();
  }

  reset(){
      this.api.getapplWiseTracker(this.Appc_id,this.user_id,this.email).subscribe(
        res => {
        
        if(res['data']!= null){
           
               this.step = res['data']['status'];
                this.ip_status = res['data']['ipdata'];
                this.opens_count = parseInt(res['data']['opens_count']);
                if (this.step === 'delivered') {
                    this.deliveredFlag = true;
                    this.stepper.next();
                    this.isLinear = true;
                    this.Mailmessage = "Mail has delivered to "+this.email;
                } else if (this.step === 'not_delivered') {
                    this.deliveredFlag = false;
                    this.isLinear = true;
                    this.Mailmessage = "Mail not delivered .";
                } 

                 if (this.opens_count > 0 ) {
                    this.stepper.next();
                    this.openedFlag= true;
                    this.OPENmessage = "Mail opened "+this.opens_count+" times.";
                    this.isLinear = true;
                }else{
                    this.openedFlag= false;
                    this.OPENmessage = "Mail not opened.";
                    this.isLinear = true;
                } 

                if (this.ip_status === 'exists' ) {
                    this.ipFlag= true;
                    this.stepper.next();
                    this.isLinear = true;
                    this.IPmsg= "IP address tracked";
                }else if (this.ip_status === 'not_exists' ) {
                    
                    this.ipFlag= false;
                    this.isLinear = true;
                    this.IPmsg= "IP address not tracked";
               }

        }
      });
  }

selectStep() {
   
    if (this.stepper.selectedIndex == 0) {
        if (this.deliveredFlag == true) {

        this.message = "Mail has delivered to "+this.email;
        this.alertFlag = 1;
        this.timer();
        } else if (this.deliveredFlag === false) {

        this.message = "Mail has not delivered to "+this.email;
        this.alertFlag = 1;
        this.timer();
        }

    } else if (this.stepper.selectedIndex == 1) {
        
        if (this.openedFlag == true) {
        
        this.message = "Mail opened "+this.opens_count+" times.";
        this.alertFlag = 1;
        this.timer();
        } else if (this.deliveredFlag == true && this.openedFlag == false) {
        this.message = "Mail deliverd but not opened";
        this.alertFlag = 1;
        this.timer();
        } else if (this.openedFlag == false) {
        this.message = "Mail not opened";
        this.alertFlag = 1;
        this.timer();
        } else {
        this.message = "You have to wait for previous steps to be completed";
        this.alertFlag = 1;
        this.timer();
        }

    } else if (this.stepper.selectedIndex == 2) {

        if (this.ipFlag === true) {

        this.message = "IP address tracked";
        this.alertFlag = 1;
        this.timer();
        } else if (this.openedFlag === true && this.ipFlag === false) {
        this.message = "Mail opened but not IP traced";
        this.alertFlag = 1;
        this.timer();
        } else if (this.ipFlag === false) {
        this.message = "IP address no tracked";
        this.alertFlag = 1;
        this.timer();
        } else {
        this.message = "You have to wait for previous steps to be completed";
        this.alertFlag = 1;
        this.timer();
        }
    } 

    }

    timer() {
        setTimeout(() => {
        this.alertFlag = 0;
        }, 5000);
    }

    onClose() {
    this.alertFlag = 0;
    this.ngOnInit();
    }

}
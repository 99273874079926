import { Component , Input, ChangeDetectionStrategy} from '@angular/core';
import { NbDialogRef,NbDialogService } from '@nebular/theme';
import { Router ,ActivatedRoute} from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
   selector: 'nb-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
   template: `<nb-card [style.width.px]="450"  [style.height.px]="300" status="success">
      <nb-card-header>
        <div class="row">
          <div class="col-md-8">
            Please Enter the message for student:
          </div>
          <div class="col-md-4" style="text-align:right">
            <nb-action icon="ion-close" (click)="close()"></nb-action>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <form [formGroup]="statementForm"  class="step-container">
        <textarea cols="30" rows="5"  [ngClass]="{'form-control-danger': statementForm.controls.statementCtrl.invalid && (statementForm.controls.statementCtrl.dirty || statementForm.controls.statementCtrl.touched)}" formControlName="statementCtrl" placeholder="Please enter the message" class="form-control">
        </textarea>
        <div *ngIf="form_submitted && statementForm.controls.statementCtrl.invalid">
          <div *ngIf="statementForm.controls.statementCtrl.required">Message is required</div>
        </div>
        </form>
      </nb-card-body>
      <nb-card-footer>
        <div class="row" >
          <div class="col-md-5"></div>
          <div class="col-md-7"><button nbButton hero status="primary" (click)="ok()">OK</button></div>
        </div>           
      </nb-card-footer>
    </nb-card>
    `,
})

export class StatementComponent  {
  @Input() userId:number;
  statementForm : FormGroup;
  @Input() errataCheck:any;
  @Input() erratChecktrans:any;
  @Input() type:string;
  @Input() appId:string;

  form_submitted: boolean=false;
  title;
  app_id: any;

  constructor(protected ref: NbDialogRef<StatementComponent>,
    protected api : ApiService,
    private fb: FormBuilder,
    private toastrService: NbToastrService,private route: ActivatedRoute,) {
  }

  ngOnInit() { 
    this.buildstatementForm();
    this.app_id = this.route.snapshot.queryParamMap.get('app_id');
    
  }

  private buildstatementForm() : void{
    this.statementForm = this.fb.group({
      statementCtrl :['',[Validators.required]],
    });
  }

  ok(){ 
    var duration = 10000;
 
    let message= this.statementForm.controls.statementCtrl.value
    if(this.type == 'ApplicationReject'){
      this.api.rejectApplication(this.userId, this.appId, "pending",message).subscribe(data => { 
                           
        if (data['status'] == 200) {
          this.toastrService.show(` ` + data['message'], 'SUCCESS', {
            duration, preventDuplicates: false
          });
          this.ref.close();

        }else{
          this.toastrService.danger(` ` + data['message'], 'DANGER', {
            duration, preventDuplicates: false
          }
          );
        }
      })
  
      
    }else{
      this.statementForm.controls.statementCtrl.markAsDirty();
      this.form_submitted = true;
      if (this.statementForm.valid) {
        var msg_from_admin_data ={
          userId : this.userId,
          message: this.statementForm.controls.statementCtrl.value,
        }
        this.api.updateErrataTranscript(msg_from_admin_data, this.errataCheck, this.app_id).subscribe(data => {
          if (data['status'] == 200) {
            if (data['status'] == 200) {
              this.toastrService.show(` ` + data['message'], 'SUCCESS', {
                duration, preventDuplicates: false
              });
              this.ngOnInit();
            } else if (data['status'] == 400) {
              this.toastrService.show(` ` + data['message'], 'DANGER', {
                duration, preventDuplicates: false
              });
            } else {
            }
          }
        });
        this.ref.close();
      }else{
        this.toastrService.show(       
          `Please Enter the message`,[this.title="Warning"]
            );  
      }   
    }
  }
    close(){
      this.ref.close();
    }
  
}
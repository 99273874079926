import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';
import { first, flatMap, switchMap } from 'rxjs/operators';

interface FCWidget {
  init: any;
  user: any;
  isOpen: any;
  open: any;
  close: any;
  track: any;
  setTags: any;
  setLocale: any;
  destroy: any;
  isInitialized: any;
  on: any;
}

@Injectable({
  providedIn: 'root',
})
export class NgxFreshBotService {
  loaded: boolean = false;
  widget ;
  constructor() {
    this.widget = this.getWidget();
  }

  /**
   * Initializes the Freshchat Widget.
   * @param data The Freshchat init object.
   * @returns An Observable which emits when the widget has loaded.
   * @author beyondsanity
   */
  init(data: any, hash: string): Observable<any> {
    return this.loadScript(
      'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js',
      hash
    ).pipe(
      first(),
      switchMap((res) => this.initWidget(data))
    );
  }

  private initWidget(data): Observable<any> {
    return new Observable((observer) => {
      
      var widget=this.getWidget();
      widget.initiateWidget(
        {
          autoInitChat: false,
          getClientParams: function () {
            return {
              'cstmr::eml': data,
            };
          },
        },
        function (successResponse: any) {
          
          //widget.showWidget(true);
        },
        function (errorResponse: any) {}
      );
      
    //   setTimeout(()=>{
    //     this.getWidget().showWidget(true);
    //    },1000)
      observer.complete();
    });
  }

//   private resetConsumer(cb: any): Observable<any> {
//     return new Observable((observer) => {
//       this.getWidget().resetConsumer(cb);
//       observer;
//     });
//   }

//   onUserCreate(): Observable<any> {
//     return new Observable((observer) => {
//       this.getWidget().on('user:created', (res: any) => {
//         if (res.status !== 200) {
//           observer.error(res.status);
//         } else {
//           observer.next(res.data || null);
//         }
//       });
//     });
//   }

  private getWidget(): any {
    return (window as any).Freshbots;
  }

  private loadScript(src: string,hash: string): Observable<any> {
    return new Observable<any>((observer: Observer<any>) => {
      var n: any = document.getElementsByTagName('script')[0];
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.id = 'spd-busns-spt';
      scriptElement.async = true;
      scriptElement.setAttribute('data-self-init', 'false');
      scriptElement.setAttribute('data-init-type', 'opt');
      scriptElement.src = src;
      scriptElement.setAttribute(
        'data-client',
        '2ef86a93b1051f814461922577b4a990f4884f67'
      );
      scriptElement.setAttribute(
        'data-bot-hash', hash
        
      );
      scriptElement.setAttribute('data-env', 'prod');
      scriptElement.setAttribute('data-region', 'in');
      scriptElement.onload = () => {
        this.loaded = true;
        observer.next(src);
        
        observer.complete();
      };
      scriptElement.onerror = () => observer.error("Couldn't load " + src);
      document.getElementsByTagName('body')[0].appendChild(scriptElement);
    });
  }
}

export var config = {
  serverUrl : "https://devmu.etranscript.in",    
  socketioUrl : "http://localhost:2",
  ENV_sendgrid_Twilio : "production",
  markListUploadUrl : "https://devmu.etranscript.in/api/attestation/uploadUserMarkList",
  transUploadUrl:"https://devmu.etranscript.in/api/attestation/upload_transcript",
  curriculumUploadUrl: "https://devmu.etranscript.in/api/attestation/upload_curriculum",
  docUploadUrl :  "https://devmu.etranscript.in/api/attestation/upload_doc",
  PaymentIssueUrl :  "https://devmu.etranscript.in/api/attestation/Upload_PaymentIssueUrl",
  WesLetterUrl :  "https://devmu.etranscript.in/api/attestation/WesLetterUrl",
  CompetencyletterUploadUrl :  "https://devmu.etranscript.in/api/attestation/upload_CompetencyLetter",
  letterUploadUrl :  "https://devmu.etranscript.in/api/attestation/upload_gradeToPercentLetter",
  NameChangeLetterUrl :  "https://devmu.etranscript.in/api/attestation/upload_letterforNameChange",
  ConfirmationDialogComponent: "",
  production: true,
}
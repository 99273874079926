import { Component, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { FormBuilder, FormGroup,Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { MatStepperModule, MatFormFieldModule } from '@angular/material';

// @Imports( {
//     MatStepperModule
// })
@Component({
selector: 'nb-dialog',
template: `

<nb-card status="success" *ngIf="performAction == 'showstatus'">
  <nb-card-header style="text-align:center;font-size: 150%;">Progress of Application</nb-card-header>
  <nb-card-body>
    <mat-horizontal-stepper *ngIf="screenWidth > 500" [linear]="isLinear" (click)="selectStep();" #progressStepper >
        <!-- [selectedIndex]="currentStep"  [selectedIndex]="selectStep" linear="statusflag5"  color="purple" color="accent" move('selectStep') -->
        <mat-step   [completed]="preapplyFlag" editable="false">
            <ng-template matStepLabel  matStepperIcon="done">preapply</ng-template>
        </mat-step>
        <mat-step  [completed]="applyFlag" [completed]="preapplyFlag"  editable="false">
            <ng-template matStepLabel matStepperIcon="done">apply</ng-template>
        </mat-step>
        <mat-step   [completed]="verificationFlag" [completed]="applyFlag" [completed]="preapplyFlag"  editable="false">
            <ng-template matStepLabel matStepperIcon="done">verification</ng-template>
        </mat-step>
        <mat-step   [completed]="verifiedFlag" [completed]="verificationFlag"  [completed]="applyFlag" [completed]="preapplyFlag"   editable="false">
            <ng-template matStepLabel matStepperIcon="done">verified</ng-template>
        </mat-step>
        <mat-step  [completed]="doneFlag"[completed]="verifiedFlag" [completed]="verificationFlag"  [completed]="applyFlag" [completed]="preapplyFlag"  editable="false">
            <ng-template matStepLabel matStepperIcon="done">done</ng-template>
        </mat-step> 
    </mat-horizontal-stepper>

    <mat-vertical-stepper  *ngIf="screenWidth <= 500"  [linear]="isLinear" (click)="selectStep();" #progressStepper >
    <!-- [selectedIndex]="currentStep"  [selectedIndex]="selectStep" linear="statusflag5"  color="purple" color="accent" move('selectStep') -->
    <mat-step   [completed]="preapplyFlag" editable="false">
        <ng-template matStepLabel  matStepperIcon="done">preapply</ng-template>
    </mat-step>
    <mat-step  [completed]="applyFlag" [completed]="preapplyFlag"  editable="false">
        <ng-template matStepLabel matStepperIcon="done">apply</ng-template>
    </mat-step>
    <mat-step   [completed]="verificationFlag" [completed]="applyFlag" [completed]="preapplyFlag"  editable="false">
        <ng-template matStepLabel matStepperIcon="done">verification</ng-template>
    </mat-step>
    <mat-step   [completed]="verifiedFlag" [completed]="verificationFlag"  [completed]="applyFlag" [completed]="preapplyFlag"   editable="false">
        <ng-template matStepLabel matStepperIcon="done">verified</ng-template>
    </mat-step>
    <mat-step  [completed]="doneFlag"[completed]="verifiedFlag" [completed]="verificationFlag"  [completed]="applyFlag" [completed]="preapplyFlag"  editable="false">
        <ng-template matStepLabel matStepperIcon="done">done</ng-template>
    </mat-step> 
</mat-vertical-stepper>
    <nb-alert status="danger" closable (close)="onClose()" *ngIf="alertFlag == 1">
      {{message}}
    </nb-alert>

    <nb-card-footer>
        <button nbButton (click)="dismiss()">close</button>
    </nb-card-footer>
  </nb-card-body>
</nb-card> 

<nb-card status="success" *ngIf="performAction == 'showimage'">
<nb-card-header>Payment Details</nb-card-header>
<nb-card-body>
    <div class="row">
        <table class="" border="1" bordercolor="#c4c4c4" style="width:100%">
            <thead>
                <tr>
                    <th style="width: 30%;padding: 8px;">Date of Payment :</th>
                    <td style="width: 60%;padding: 8px;"><span id="date_of_payment" *ngIf="paymentData != undefined">{{paymentData.payment_date}}</span></td>
                </tr>
                <tr>
                    <th style="width: 30%;padding: 8px;">Transaction Id :</th>
                    <td style="width: 60%;padding: 8px;"><span id="t_transaction_id" *ngIf="paymentData != undefined">{{paymentData.transaction_id}}</span></td>
                </tr>
                <tr>
                    <th style="width: 30%;padding: 8px;">Application Id :</th>
                    <td style="width: 60%;padding: 8px;"><span id="app_id">{{Appc_id}}</span></td>
                </tr>
                <tr>
                    <th style="width: 30%;padding: 8px;">Order Id :</th>
                    <td style="width: 60%;padding: 8px;"><span id="order_id" *ngIf="paymentData != undefined">{{paymentData.order_id}}</span></td>
                </tr>
                <tr>
                    <th style="width: 30%;padding: 8px;">Currency :</th>
                    <td style="width: 60%;padding: 8px;"><span id="t_currency" *ngIf="paymentData != undefined">{{paymentData.currency}}</span></td>
                </tr>
                <tr>
                    <th style="width: 30%;padding: 8px;">Total Amount :</th>
                    <td style="width: 60%;padding: 8px;"><span id="amount" *ngIf="paymentData != undefined">{{paymentData.amount}}</span></td>
                </tr>
            </thead>
            <br>
        </table>
    </div>
    <nb-card-footer>
        <button nbButton (click)="dismiss()">close</button>
    </nb-card-footer>
</nb-card-body>
</nb-card> 

`,
})
export class ShowStepperforApp {

 @Input()  performAction: any;
 @Input() Appc_id: any;
 @Input() user_id: string;
  linearMode = true;
  @ViewChild('progressStepper') stepper;
  message: string;
  alertFlag: number;
  statusflag: number;
  preapplyFlag: boolean;
  applyFlag: boolean;
  verificationFlag: boolean;
  verifiedFlag: boolean;
  doneFlag: boolean;

  isLinear = false;
  statusflag1: boolean;
  statusflag2: boolean;
  statusflag3: boolean;
  statusflag4: boolean;
  statusflag5: boolean;

  stepperFlag : Boolean = false;
  allDataFetched: boolean = false;
  screenWidth: number;
  paymentData: any;
    num: number;
    Role: string;

  constructor(protected ref: NbDialogRef<ShowStepperforApp>,
    protected api : ApiService,
    private fb: FormBuilder,
    private authService : NbAuthService,
    private router : Router,
  ) {
    this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
            if(token.getPayload()['role'] =="admin"){
                this.Role = "Admin";
            }
    });
  
  }

  ngOnInit() {
        this.screenWidth = window.innerWidth;
      
    
        if(this.Role =="Admin"){
            this.api.getApplWisePaymentsAdmin(this.Appc_id,this.user_id).subscribe(data  =>{
                this.paymentData = data['data'][0];
            });

        }else{
            this.api.getApplWisePayments(this.Appc_id).subscribe(data  =>{
                this.paymentData = data['data'][0];
            });
        }
    this.reset();
  }

  dismiss() {
    this.ref.close();
  }

  reset(){
      this.api.getStatuswithid(this.Appc_id).subscribe(
        res => {
         if(res['data']!= null){
          
                var step = res['data']['tracker'];
                if (step === 'preapply') {
                  this.statusflag = 1;
                  this.statusflag1 = true;
                  this.preapplyFlag = true;
                  this.applyFlag = false;
                  this.verificationFlag = false;
                  this.verifiedFlag = false;
                  this.doneFlag = false;
                  this.isLinear = true;
                } else if (step === 'apply') {
                  this.statusflag = 2;
                  this.statusflag2 = true;
                  this.preapplyFlag = true;
                  this.applyFlag = true;
                  this.verificationFlag = false;
                  this.verifiedFlag = false;
                  this.doneFlag = false;
                  this.isLinear = true;
                } else if (step === 'verification') {
                  this.statusflag = 3;
                  this.statusflag3 = true;
                  this.preapplyFlag = true;
                  this.applyFlag = true;
                  this.verificationFlag = true;
                  this.verifiedFlag = false;
                  this.doneFlag = false;
                  this.isLinear = true;
                } else if (step === 'verified') {
                  this.statusflag = 4;
                  this.statusflag4 = true;;
                  this.preapplyFlag = true;
                  this.applyFlag = true;
                  this.verificationFlag = true;
                  this.verifiedFlag = true;
                  this.doneFlag = false;
                  this.isLinear = true;
                } else if (step === 'done') {
                
                  this.statusflag = 5;
                  this.statusflag5 = true;
                
                  this.preapplyFlag = true;
                  this.applyFlag = true;
                  this.verificationFlag = true;
                  this.verifiedFlag = true;
                  this.doneFlag = true;
                  this.isLinear = true;
                }
                
                this.stepperFlag = true;
              this.allDataFetched = true;
             }
      });
  }

    selectStep() {
    if (this.stepper.selectedIndex == 0) {
        if (this.preapplyFlag == true) {

        this.message = "You have completed preapply state";
        this.alertFlag = 1;
        this.timer();
        } else if (this.preapplyFlag === false) {

        this.message = "You have not apply for application of attestation";
        this.alertFlag = 1;
        this.timer();
        }

    } else if (this.stepper.selectedIndex == 1) {
        
        if (this.applyFlag == true) {
        
        this.message = "You have successfully applied for application of attestation";
        this.alertFlag = 1;
        this.timer();
        } else if (this.preapplyFlag == true && this.applyFlag == false) {
        this.message = "You have not applied for application of attestation";
        this.alertFlag = 1;
        this.timer();
        } else if (this.applyFlag == false) {
        this.message = "first You have to apply for application";
        this.alertFlag = 1;
        this.timer();
        } else {
        this.message = "You have to wait for previous steps to be completed";
        this.alertFlag = 1;
        this.timer();
        }

    } else if (this.stepper.selectedIndex == 2) {

        if (this.verificationFlag === true) {

        this.message = "verification done";
        this.alertFlag = 1;
        this.timer();
        } else if (this.applyFlag === true && this.verificationFlag === false) {
        this.message = "You have applied for application but it did not reach at verification pannel till now";
        this.alertFlag = 1;
        this.timer();
        } else if (this.verificationFlag === false) {
        this.message = "This step not completed";
        this.alertFlag = 1;
        this.timer();
        } else {
        this.message = "You have to wait for previous steps to be completed";
        this.alertFlag = 1;
        this.timer();
        }
    } else if (this.stepper.selectedIndex == 3) {

        if (this.verifiedFlag === true) {

        this.message = "Your application successfully verified";
        this.alertFlag = 1;
        this.timer();
        } else if (this.verificationFlag === true  && this.verifiedFlag === false) {
        this.message = "Your application not verified till now";
        this.alertFlag = 1;
        this.timer();
        } else if (this.verifiedFlag === false) {
        this.message = "You have to wait for previous steps to be completed";
        this.alertFlag = 1;
        this.timer();
        } else {
        this.message = "You have to wait for previous steps to be completed";
        this.alertFlag = 1;
        this.timer();
        }

    } else if (this.stepper.selectedIndex == 4) {

        if (this.doneFlag === true) {

        this.message = "Congratulations! Your application has been signed and it is send to your mail id ";
        this.alertFlag = 1;
        this.timer();
        } else if (this.verifiedFlag === true  && this.doneFlag === false) {
        this.message = "Sorry! Your application is verified but not signed yet.";
        this.alertFlag = 1;
        this.timer();
        } else if (this.doneFlag === false) {
        this.message = "You have to wait for previous steps to be completed";
        this.alertFlag = 1;
        this.timer();
        } else {
        this.message = "You have to wait for previous steps to be completed";
        this.alertFlag = 1;
        this.timer();
        } }

    }

    timer() {
        setTimeout(() => {
        this.alertFlag = 0;
        }, 5000);
    }

    onClose() {
    this.alertFlag = 0;
    this.ngOnInit();
    }

    //check whether it is use ful or not
    move(index: number) {
        this.num=this.stepper.selectedIndex = index;
        return index;
    }
}
import { Component,HostListener, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { FormBuilder, FormGroup,Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../shared/api.service';
import { CountriesService } from '../../../../@core/data/countries.service';
import { NbToastrService} from '@nebular/theme';


@Component({
selector: 'nb-dialog',
template: `

<nb-card status="success">
  <nb-card-header style="text-align:center;font-size: 150%;">{{info_type}}  
    <div class="right" (click)="dismiss();">
      <i title="Back"  class="fas fa-arrow-left fa-pull-right fa-border"></i>
    </div> 
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="institutionForm" *ngIf="SHOW == true">
      <div class="row">
        <div class="col-md-12" style ="color: red;">
          <p >Note :1. Document uploaded will be provided to the concerned agency/university , university does not check if your document meet their requirements. 
            <span *ngIf = "iqasTrue == true || icesTrue == true || nasbaTrue == true">2. Documents will send on all email addresses</span>
          </p>
        </div>
      </div>
       
      <div class="row"  *ngIf="otherField">
        <div class="col-md-4">
          <span *ngIf="info_type == 'University Details'">University</span>
          <span *ngIf="info_type == 'Company'">Company</span> Name
        </div>
        <div class="col-md-8">
          <input type="text"  *ngIf="otherField" class="form-control" [(ngModel)]="universityName" formControlName="universityNameCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.universityNameCtrl.invalid && (institutionForm.controls.universityNameCtrl.dirty || institutionForm.controls.universityNameCtrl.touched)}" 
           (keyup)="onKey($event)">   
        </div>
      </div>
       
      <div class="row"  *ngIf="otherField">
        <div class="col-md-4">Country</div>
        <div class="col-md-8">
          <mat-select   *ngIf="otherField" class="form-control"  [(ngModel)]="country" name="selectedCountry" formControlName="countryCtrl" >
            <mat-option *ngFor="let country of Countries" [value]="country.id">
              {{country.name}}
            </mat-option>
          </mat-select>
          <mat-autocomplete #Countryauto="matAutocomplete">
            <mat-option *ngFor="let option of country_nameAutoS" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete> 
        </div>
      </div>
       
      <div class="row"  *ngIf="otherField">
        <div class="col-md-4">Contact Person Name</div>
        <div class="col-md-8">
          <input type="text"  *ngIf="otherField" class="form-control" [(ngModel)]="contactperson" formControlName="contactpersonCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.contactpersonCtrl.invalid && (institutionForm.controls.contactpersonCtrl.dirty || institutionForm.controls.contactpersonCtrl.touched)}">   
        </div>
      </div>
       
      <div class="row"  *ngIf="otherField">
        <div class="col-md-4">Contact Number</div>
        <div class="col-md-8">
          <input type="tel" class="form-control" (keypress)="numberOnly($event)" [(ngModel)]="contact" formControlName="contactCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.contactCtrl.invalid && (institutionForm.controls.contactCtrl.dirty || institutionForm.controls.contactCtrl.touched)}"
          [matAutocomplete]="ContactPersonNumberauto">   
          <mat-autocomplete #ContactPersonNumberauto="matAutocomplete">
            <mat-option *ngFor="let option of contact_numberAutoS" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete> 
        </div>
      </div>
       
      <div class="row" *ngIf="wesTrue">
        <div class="col-md-4">WES Reference No</div>
        <div class="col-md-1">
          <label style="padding-left:80%;">MU-</label>  
        </div>
        <div class="col-md-7" >
          <input type="text" class="form-control" formControlName="wesIdCtrl" minlength="7" maxlength="8"
          (keypress)="numberOnly($event)" [ngClass]="{'form-control-danger': institutionForm.controls.wesIdCtrl.invalid && (institutionForm.controls.wesIdCtrl.dirty || institutionForm.controls.wesIdCtrl.touched)}">
          <mat-hint>Enter 7-digit numeric values.</mat-hint>
        </div>
      </div>
       
      <div class="row" *ngIf="wesTrue">
        <div class="col-md-4">Name as per Wes Application/Registration</div>
        <div class="col-md-8">
          <input  type="text" class="form-control" formControlName="nameaswesctrl"   [ngClass]="{'form-control-danger': institutionForm.controls.nameaswesctrl.invalid && (institutionForm.controls.nameaswesctrl.dirty || institutionForm.controls.nameaswesctrl.touched)}"  >                   
        </div>
      </div>
       
      <div class="row" *ngIf="wesTrue">
        <div class="col-md-4">Surname as per Wes Application/Registration</div>
        <div class="col-md-8">
          <input  type="text" class="form-control" formControlName="lastnameaswesctrl" 
         [ngClass]="{'form-control-danger': institutionForm.controls.lastnameaswesctrl.invalid && (institutionForm.controls.lastnameaswesctrl.dirty || institutionForm.controls.lastnameaswesctrl.touched)}">
        </div>
      </div>
       
      <div class="row" *ngIf="wesTrue">
        <div class="col-md-4">Registered email id as per WES registration</div>
        <div class="col-md-8">
          <input type="text" class="form-control" formControlName="emailAsWesCtrl" 
          [(ngModel)]="emailAsWes" [ngClass]="{'form-control-danger': institutionForm.controls.emailAsWesCtrl.invalid && (institutionForm.controls.emailAsWesCtrl.dirty || institutionForm.controls.emailAsWesCtrl.touched)}">
        </div> 
      </div>
       
      <div class="row" *ngIf="wesTrue">
        <div class="col-md-4"></div>
        <div class="col-md-8" *ngIf="show_edit_error == true" style ="color: red;">
          Do not add wes email id. Add your email id as per wes registartion.
        </div>
      </div>
       
      <div class="row" *ngIf="eduPerTrue">
        <div class="col-md-4">EP NO</div>
        <div class="col-md-8">
          <input type="text" class="form-control"  (keypress)="numberOnly($event)" formControlName="eduPerCtrl" minlength="6" maxlength="6" [ngClass]="{'form-control-danger': institutionForm.controls.eduPerCtrl.invalid && (institutionForm.controls.eduPerCtrl.dirty || institutionForm.controls.eduPerCtrl.touched)}"> 
          <mat-hint>Enter 6-digit numeric values.</mat-hint>   
        </div>
      </div>
       
      <div class="row" *ngIf="cesTrue">
        <div class="col-md-4">CES NO</div>
        <div class="col-md-1">
          <label style="padding-left:80%;" >Mu-</label>
        </div>
        <div class="col-md-7">
          <input type="text" class="form-control" maxlength="8" minlength="2" formControlName="cesCtrl"  [ngClass]="{'form-control-danger': institutionForm.controls.cesCtrl.invalid && (institutionForm.controls.cesCtrl.dirty || institutionForm.controls.cesCtrl.touched)}"> 
          <mat-hint>Enter alphanumeric values.</mat-hint>
        </div>
      </div>
       
      <div class="row" *ngIf="nceesTrue">
        <div class="col-md-4">NCEES NO</div>
        <div class="col-md-1">
          <label style="padding-left:80%;">MU-</label>
        </div>
        <div class="col-md-7">
          <input type="text" class="form-control" formControlName="nceesCtrl" maxlength='10' minlength="2"  [ngClass]="{'form-control-danger': institutionForm.controls.nceesCtrl.invalid && (institutionForm.controls.nceesCtrl.dirty || institutionForm.controls.nceesCtrl.touched)}"> 
          <mat-hint>Enter alphanumeric  values</mat-hint>
        </div>
      </div>
       
      <div class="row" *ngIf="otheraccTrue">
        <div class="col-md-4">Reference no.</div>
        <div class="col-md-8">
          <input type="text" class="form-control" minlength="2" maxlength="10"   formControlName="otherAccCtrl"
          [ngClass]="{'form-control-danger': institutionForm.controls.otherAccCtrl.invalid && (institutionForm.controls.otherAccCtrl.dirty || institutionForm.controls.otherAccCtrl.touched)}"> 
        </div>
      </div>
       
      <div class="row" *ngIf="iqasTrue">
        <div class="col-md-4">IQAS NO</div>
        <div class="col-md-1">
          <label style="padding-left:80%;">MU-</label>
        </div>
        <div class="col-md-7">
          <input type="text" class="form-control" (keypress)="numberOnly($event)" formControlName="iqasCtrl" minlength="6" maxlength="6" [ngClass]="{'form-control-danger': institutionForm.controls.iqasCtrl.invalid && (institutionForm.controls.iqasCtrl.dirty || institutionForm.controls.iqasCtrl.touched)}"> 
          <mat-hint>Enter 6-digit numeric values only.</mat-hint>
        </div>
      </div>
       
      <div class="row" *ngIf="icasTrue">
        <div class="col-md-4">ICAS NO</div>
        <div class="col-md-1">
          <label style="padding-left:80%;">MU-</label>
        </div>
        <div class="col-md-7" >
          <input type="text" class="form-control" (keypress)="numberOnly($event)"  formControlName="icasCtrl" minlength="2" maxlength="10" [ngClass]="{'form-control-danger': institutionForm.controls.icasCtrl.invalid && (institutionForm.controls.icasCtrl.dirty || institutionForm.controls.icasCtrl.touched)}"> 
          <mat-hint>Enter numeric values.</mat-hint>
        </div>
      </div>
       
      <div class="row" *ngIf="iqasTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" readonly formControlName="iqasEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.iqasEmailCtrl.invalid && (institutionForm.controls.iqasEmailCtrl.dirty || institutionForm.controls.iqasEmailCtrl.touched)}">
        </div>
      </div>
       
      <div class="row" *ngIf="icasTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" readonly formControlName="icasEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.icasEmailCtrl.invalid && (institutionForm.controls.icasEmailCtrl.dirty || institutionForm.controls.icasEmailCtrl.touched)}">
        </div>
      </div>
       
      <div class="row" *ngIf="naricTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" readonly formControlName="naricEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.naricEmailCtrl.invalid && (institutionForm.controls.naricEmailCtrl.dirty || institutionForm.controls.naricEmailCtrl.touched)}">
        </div>
      </div>
       
      <div class="row" *ngIf="ncaTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" readonly formControlName="ncaEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.ncaEmailCtrl.invalid && (institutionForm.controls.ncaEmailCtrl.dirty || institutionForm.controls.ncaEmailCtrl.touched)}">
        </div>
      </div>
       
      <div class="row" *ngIf="nasbaTrue">
        <div class="col-md-4">NIES Id.</div>
        <div class="col-md-1">
          <label style="padding-left:80%;">MU-</label>
        </div>
        <div class="col-md-7">
          <input type="text" class="form-control" (keypress)="numberOnly($event)" maxlength="6" minlength="5" formControlName="nasbaCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.nasbaCtrl.invalid && (institutionForm.controls.nasbaCtrl.dirty || institutionForm.controls.nasbaCtrl.touched)}">
          <mat-hint>Enter  numeric values.</mat-hint>
        </div>
      </div>
       
      <div class="row" *ngIf="nasbaTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" readonly formControlName="nasbaEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.nasbaEmailCtrl.invalid && (institutionForm.controls.nasbaEmailCtrl.dirty || institutionForm.controls.nasbaEmailCtrl.touched)}">
        </div>
      </div>
       
      <div class="row" *ngIf="cesTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" readonly formControlName="cesEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.cesEmailCtrl.invalid && (institutionForm.controls.cesEmailCtrl.dirty || institutionForm.controls.cesEmailCtrl.touched)}">
        </div>
      </div>
       
      <div class="row" *ngIf="nceesTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" readonly formControlName="nceesEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.nceesEmailCtrl.invalid && (institutionForm.controls.nceesEmailCtrl.dirty || institutionForm.controls.nceesEmailCtrl.touched)}">
        </div>
      </div>
       
      <div class="row" *ngIf="studyTrue">
        <div class="col-md-4">Study ref.no <span style="color : red">(Input the reference number of the recipient University/Agency wherein you want to send the documents)</span></div>
        <div class="col-md-8">
          <input type="text" class="form-control"   formControlName="studyRefCtrl"   [ngClass]="{'form-control-danger': institutionForm.controls.studyRefCtrl.invalid && (institutionForm.controls.studyRefCtrl.dirty || institutionForm.controls.studyRefCtrl.touched)}">  
        </div>
      </div>
       <br>
      <div class="row" *ngIf="visaTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control"  [(ngModel)]="email" formControlName="visaEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.visaEmailCtrl.invalid && (institutionForm.controls.visaEmailCtrl.dirty || institutionForm.controls.visaEmailCtrl.touched)}" [matAutocomplete]="Emailauto">
          <mat-autocomplete #Emailauto="matAutocomplete">
            <mat-option *ngFor="let option of EmailAutoS" [value]="option"> {{ option }} </mat-option>  
          </mat-autocomplete> 
        </div>
      </div>
       
      <div class="row" *ngIf="studyTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" [(ngModel)]="email" formControlName="studyEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.studyEmailCtrl.invalid && (institutionForm.controls.studyEmailCtrl.dirty || institutionForm.controls.studyEmailCtrl.touched)}"
          [matAutocomplete]="Emailauto">
          <mat-autocomplete #Emailauto="matAutocomplete">
            <mat-option *ngFor="let option of EmailAutoS" [value]="option">
              {{ option }}
            </mat-option>  
          </mat-autocomplete> 
        </div>
      </div>
       
      <div class="row" *ngIf="employment">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" [(ngModel)]="email" formControlName="empEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.empEmailCtrl.invalid && (institutionForm.controls.empEmailCtrl.dirty || institutionForm.controls.empEmailCtrl.touched)}"
          [matAutocomplete]="Emailauto">
          <mat-autocomplete #Emailauto="matAutocomplete">
            <mat-option *ngFor="let option of EmailAutoS" [value]="option">
              {{ option }}
            </mat-option>  
          </mat-autocomplete> 
        </div>
      </div>
       
      <div class="row" *ngIf="otheraccTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" [(ngModel)]="email" formControlName="otherEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.otherEmailCtrl.invalid && (institutionForm.controls.otherEmailCtrl.dirty || institutionForm.controls.otherEmailCtrl.touched)}"
          [matAutocomplete]="Emailauto">
          <mat-autocomplete #Emailauto="matAutocomplete">
            <mat-option *ngFor="let option of EmailAutoS" [value]="option">
              {{ option }}
            </mat-option>  
          </mat-autocomplete> 
        </div>
      </div>
       
      <div class="row" *ngIf="visaTrue">
        <div class="col-md-4">Application ref.no <span style="color : red">(Input the reference number of the recipient University/Agency wherein you want to send the documents)</span></div>
        <div class="col-md-8">
          <input type="text" class="form-control" formControlName="visaRefCtrl"   maxlength="10"  [ngClass]="{'form-control-danger': institutionForm.controls.visaRefCtrl.invalid && (institutionForm.controls.visaRefCtrl.dirty || institutionForm.controls.visaRefCtrl.touched)}"> 
        </div>
      </div>
       
      <div class="row" *ngIf="myieeTrue">
        <div class="col-md-4">MYIEE no.</div>
        <div class="col-md-1">
          <label style="padding-left:80%;">Mu-</label>
        </div>
        <div class="col-md-7">
          <input type="text" class="form-control"  formControlName="myieeCtrl"  minlength="2" maxlength="10"  [ngClass]="{'form-control-danger': institutionForm.controls.myieeCtrl.invalid && (institutionForm.controls.myieeCtrl.dirty || institutionForm.controls.myieeCtrl.touched)}"> 
          <mat-hint>Enter alphanumeric values.</mat-hint>
        </div>
      </div>
       
      <div class="row" *ngIf="ncaTrue">
        <div class="col-md-4">National Committee on Accreditation no.</div>
        <div class="col-md-1"><label style="padding-left:80%;">MU-</label></div>
        <div class="col-md-7">
          <input type="text" class="form-control" formControlName="ncaCtrl" (keypress)="numberOnly($event)" minlength="2" maxlength="10"  [ngClass]="{'form-control-danger': institutionForm.controls.ncaCtrl.invalid && (institutionForm.controls.ncaCtrl.dirty || institutionForm.controls.ncaCtrl.touched)}"> 
          <mat-hint>Enter numeric values.</mat-hint>
        </div>
      </div>
       
      <div class="row" *ngIf="myieeTrue">
        <div class="col-md-4">Email </div>
        <div class="col-md-8">
          <input type="text" class="form-control"  readonly formControlName="myieeEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.myieeEmailCtrl.invalid && (institutionForm.controls.myieeEmailCtrl.dirty || institutionForm.controls.myieeEmailCtrl.touched)}"> 
        </div>
      </div>
       
      <div class="row" *ngIf="icesTrue">
        <div class="col-md-4">ICES no.</div>
        <div class="col-md-1">
          <label style="padding-left:80%;">MU-</label>
        </div>
        <div class="col-md-7">
          <input type="text" class="form-control" maxlength="12" formControlName="icesCtrl" minlength="9" maxlength="10" [ngClass]="{'form-control-danger': institutionForm.controls.icesCtrl.invalid && (institutionForm.controls.icesCtrl.dirty || institutionForm.controls.icesCtrl.touched)}"> 
          <mat-hint>Enter alphanumeric values.</mat-hint>
        </div>
      </div>
       
      <div class="row" *ngIf="icesTrue">
        <div class="col-md-4">Email</div>
        <div class="col-md-8">
          <input type="text" class="form-control"  readonly formControlName="icesEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.icesEmailCtrl.invalid && (institutionForm.controls.icesEmailCtrl.dirty || institutionForm.controls.icesEmailCtrl.touched)}"> 
        </div>
      </div>
       
      <div class="row" *ngIf="employment">
        <div class="col-md-4">Employment ref.no <span style="color : red">(Input the reference number of the recipient University/Agency wherein you want to send the documents)</span></div>
        <div class="col-md-8">
          <input type="text" class="form-control" formControlName="empRefCtrl" maxlength="10"  [ngClass]="{'form-control-danger': institutionForm.controls.empRefCtrl.invalid && (institutionForm.controls.empRefCtrl.dirty || institutionForm.controls.empRefCtrl.touched)}"> 
        </div>
      </div>
      <div class="row" *ngIf="eduPerTrue">
        <div class="col-md-4">E-mail</div>
        <div class="col-md-8">
          <input type="text" class="form-control" readonly formControlName="eduEmailCtrl" [ngClass]="{'form-control-danger': institutionForm.controls.eduEmailCtrl.invalid && (institutionForm.controls.eduEmailCtrl.dirty || institutionForm.controls.eduEmailCtrl.touched)}">
        </div>
      </div>
       
      <div class="row" *ngIf="naricTrue">
        <div class="col-md-4">NARIC no.</div>
        <div class="col-md-1">
          <label style="padding-left:80%;">MU-</label>
        </div>
        <div class="col-md-7">
          <input type="text" class="form-control" formControlName="naricCtrl" maxlength="13" minlenth="1" [ngClass]="{'form-control-danger': institutionForm.controls.naricCtrl.invalid && (institutionForm.controls.naricCtrl.dirty || institutionForm.controls.naricCtrl.touched)}"> 
          <mat-hint>Enter alphanumeric values.</mat-hint>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div  style="justify-content: center;text-align: center;">
      <button nbButton (click)="saveInstitution()">Save</button>
    </div>
    <span *ngIf="errflag" style="color:red">Please fill all the field</span>
  </nb-card-footer>
</nb-card>`,
})
export class Addinstitutiondialog {
  @Input() institute : any;  
  @Input() function_type :string;
  @Input() info_type : string;
  @Input() save_type : string;

  otherField:boolean = false;
  data: any;
  old_email ; 
  user_id;
  SHOW:boolean;
  //NEW
  institutionForm : FormGroup;
  readonly emailValidate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  readonly mobileValidate = /^[0-9]\d{5,12}$/;
 
  msgs: any[];
  universityName;
  email;
  country:Number;
  contact;
  contactperson;
  universitynames : Observable<any[]>;
  EmailAutoS = [];
  country_nameAutoS = [];
  contact_personAutoS = [];
  contact_numberAutoS = [];
  Countries: any [];
  showList: boolean = true;
  wesTrue: boolean = false;
  cesTrue: boolean = false;
  eduPerTrue: boolean = false;
  iqasTrue: boolean = false;
  icasTrue: boolean = false;
  icesTrue: boolean = false;
  nasbaTrue : boolean = false;
  nceesTrue : boolean = false;
  wesno: any;
  epno: any;
  icasno: any;
  iqasno: any;
  cesno: any;
  studyTrue:boolean = false;
  visaTrue:boolean = false;
  employment:boolean= false;
  studyrefno:any;
  visarefno:any;
  emprefno:any;
  otheraccno: any;
  studyaccno: any;
  empaccno: any;
  visaaccno: any;
  otheraccTrue: boolean = false;
  myieeTrue: boolean = false;
  nceesno: any;
  naricTrue: boolean;
  naricno: any;
  show_edit_error: boolean=false;
  emailAsWes: any;
  ncaTrue: boolean;
  ncano: any;
  type: string = null;
  wesvalue: any;
  icasCtrl: any;
  iqasCtrl: any;
  cesCtrl: any;
  myieeCtrl: any;
  icesCtrl: any;
  nasbaCtrl: any;
  nceesCtrl: any;
  naricCtrl: any;
  ncaCtrl: any;
  nameaswes;
  name: any;
  surname: any;
  errflag: boolean=false;
  wesemail: any;
  firstName: any;
  lastName: any;
  savewes: boolean;

  constructor(protected ref: NbDialogRef<Addinstitutiondialog>,
    protected api : ApiService,
    private fb: FormBuilder,
    protected countries :CountriesService,
    private toasterService : NbToastrService,
  ) {
    this.Countries = this.countries.getData();
    this.api.suggestInstitute().subscribe((data)=>{
      this.universitynames = data['data'];
    })
  }

  ngOnInit() {
    if(this.info_type==='Educational credential evaluators WES'){
      this.wesTrue = true;
      this.otherField = false;
      this.institutionForm = this.fb.group({
      //  universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
      //  emailCtrl : [''],
      //  // emailCtrl : ['', [Validators.required, Validators.pattern(this.emailValidate)]],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       wesIdCtrl :['',[Validators.required,Validators.minLength(7),Validators.maxLength(8)]],
       emailAsWesCtrl : ['', [Validators.required, Validators.pattern(this.emailValidate)]],
       nameaswesctrl:['',[Validators.required]],
       lastnameaswesctrl:['',[Validators.required]],
     });
     }
     else if(this.info_type==='ICES'){
      this.icesTrue = true;
      this.institutionForm = this.fb.group({
      //  universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       icesEmailCtrl : ['icesinfo@bcit.ca, icesofficialdocs@bcit.ca'],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       icesCtrl :['',[Validators.required,Validators.minLength(9),Validators.maxLength(10)]]
     });
     }
     else if(this.info_type==='NASBA'){
      this.nasbaTrue = true;
      this.institutionForm = this.fb.group({
      //  universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       nasbaEmailCtrl : ['etranscriptnies@nasba.org, nies@nasba.org'],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       nasbaCtrl :['',[Validators.required,Validators.minLength(5),Validators.maxLength(6)]]
     });
     }
     else if(this.info_type==='IQAS'){
      this.iqasTrue = true;
      this.institutionForm = this.fb.group({
      //  universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       iqasEmailCtrl : ['lbr.iqas@gov.ab.ca'],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       iqasCtrl :['',[Validators.required,Validators.minLength(6),Validators.maxLength(6)]]
     });
     }
     else if(this.info_type==='ICAS'){
      this.icasTrue = true;
      this.institutionForm = this.fb.group({
      //  universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       icasEmailCtrl  : ['documents@icascanada.ca'],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       icasCtrl :['',[Validators.required,Validators.minLength(2),Validators.maxLength(10)]]
     });
     }
     else if(this.info_type==='Educational Perspective'){
      this.eduPerTrue = true;
      this.otherField = true;
      this.institutionForm = this.fb.group({
        universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       eduEmailCtrl : ['documents@edperspective.org'],
       countryCtrl : ['', [Validators.required]],
       contactCtrl :  ['',[Validators.required, Validators.pattern(this.mobileValidate)]],
       contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       eduPerCtrl :['',[Validators.required,Validators.minLength(6),Validators.maxLength(6)]]
     });
     }
     else if(this.info_type==='University Details'){
      this.studyTrue = true;
      this.otherField=true;
      this.institutionForm = this.fb.group({
       universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       studyEmailCtrl:['',[Validators.required,Validators.pattern(this.emailValidate)]],
       countryCtrl : ['', [Validators.required]],
       contactCtrl :  ['',[Validators.required, Validators.pattern(this.mobileValidate)]],
       contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       studyRefCtrl :[''],
       //studyAccCtrl:['']
     });
     }
     else if(this.info_type==='Counsel'){
      this.visaTrue = true;
      this.otherField=true;
      this.institutionForm = this.fb.group({
       universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       countryCtrl : ['', [Validators.required]],
       visaEmailCtrl:['',[Validators.required,Validators.pattern(this.emailValidate)]],
       contactCtrl :  ['',[Validators.required, Validators.pattern(this.mobileValidate)]],
       contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       visaRefCtrl :[''],
       visaAccCtrl:['']
     });
     }
     else if(this.info_type==='Company'){
      this.employment = true;
      this.otherField = true;
      this.institutionForm = this.fb.group({
       universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       empEmailCtrl:['',[Validators.required,Validators.pattern(this.emailValidate)]],
       countryCtrl : ['', [Validators.required]],
       contactCtrl :  ['',[Validators.required, Validators.pattern(this.mobileValidate)]],
       contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       empRefCtrl :[''],
       empAccCtrl:['']
     });
     }
     else if(this.info_type==='CES'){
      this.cesTrue = true;
      this.institutionForm = this.fb.group({
      //  universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       cesEmailCtrl : ['ces.transcripts@utoronto.ca'],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       cesCtrl :['',[Validators.required,Validators.maxLength(8), Validators.minLength(2)]]
     });
     }
     else if(this.info_type==='MYIEE'){
      this.myieeTrue= true;
      this.institutionForm = this.fb.group({
      // universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       myieeEmailCtrl : ['verifications@myiee.org, records@myiee.org'],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       myieeCtrl :['',[Validators.required,Validators.minLength(2),Validators.maxLength(10)]]
     });
     }
     else if(this.info_type==='NCEES'){
      this.nceesTrue = true;
      this.institutionForm = this.fb.group({
      //  universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
      nceesEmailCtrl : ['transcripts@ncees.org'],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
      nceesCtrl :['',[Validators.required,Validators.minLength(2),Validators.maxLength(10)]]
     });
     }
     else if(this.info_type==='NARIC'){
      this.naricTrue = true;
      this.institutionForm = this.fb.group({
      //  universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
      naricEmailCtrl : ['documents@ecctis.com'],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
      naricCtrl :['',[Validators.required,Validators.minLength(1),Validators.maxLength(13)]]
     });
     }
     else if(this.info_type==='National Committee on Accreditation'){
      this.ncaTrue = true;
      this.institutionForm = this.fb.group({
      //  universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
      ncaEmailCtrl : ['transcripts@flsc.ca, e-transcripts@flsc.ca'],
      //  countryCtrl : ['', [Validators.required]],
      //  contactCtrl :  ['',Validators.pattern(this.mobileValidate)],
      //  contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
      ncaCtrl :['',[Validators.required,Validators.minLength(2),Validators.maxLength(10)]]
     });
     }
     else{
       this.otheraccTrue = true;
       this.otherField = true;
       this.institutionForm = this.fb.group({
         universityNameCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
         otherAccCtrl:[''],
         otherEmailCtrl : ['',[Validators.required,Validators.pattern(this.emailValidate)]],
         countryCtrl : ['', [Validators.required]],
         contactCtrl :  ['',[Validators.required, Validators.pattern(this.mobileValidate)]],
         contactpersonCtrl : ['', [Validators.required, Validators.maxLength(200), Validators.minLength(3)]],
       });
     }
   if(this.function_type == 'add'){
      // this.universityName ='',
      // this.email ='',
      // this.country = '',
      // this.contact ='',
      // this.contactperson= ''
      if(this.institute.id != null){
        this.type = 'edit';
      }
    }else if(this.function_type == 'edit'){
      this.info_type =  this.institute.type,
      this.universityName = this.institute.university_name,
      this.email = this.institute.email,
      this.country = parseInt(this.institute.country_name , 10),
      this.nameaswes=this.institute.nameaswes,
      this.name=this.institute.nameaswes,
      this.surname=this.institute.lastnameaswes,
      //this.institutionForm.controls.countryCtrl.patchValue(this.country)
      this.contact = this.institute.contact_number,
      this.contactperson= this.institute.contact_person,
      this.wesno = this.institute.wesNumber,
      this.epno = this.institute.eduperno,
      this.cesno = this.institute.cesno,
      this.iqasno = this.institute.iqasno,
      this.icasno = this.institute.icasno,
      this.studyrefno = this.institute.studyrefno,
      this.emprefno = this.institute.emprefno,
      this.visarefno = this.institute.visarefno,
      this.otheraccno = this.institute.otheraccno
      this.studyaccno = this.institute.studyaccno,
      this.empaccno = this.institute.empaccno,
      this.visaaccno = this.institute.visaaccno,
      this.nceesno = this.institute.nceesno,
      this.naricno = this.institute.naricno,
      this.ncano = this.institute.ncano,
      this.emailAsWes = this.institute.emailAsWes
      if(this.info_type==='Educational credential evaluators WES'){
        //this.institutionForm.controls.wesIdCtrl.patchValue(this.institute.reference_no);
       
          const wesvalue=this.institute.reference_no.split("MU-")
          this.wesvalue=wesvalue[1];
         this.institutionForm.controls.wesIdCtrl.setValue(this.wesvalue);   
         this.institutionForm.controls.nameaswesctrl.patchValue(this.institute.nameaswes);
         this.institutionForm.controls.lastnameaswesctrl.patchValue(this.institute.lastnameaswes);
        }
        else if(this.info_type==='ICAS'){
         // this.institutionForm.controls.icasCtrl.patchValue(this.institute.reference_no);
          const icasCtrl=this.institute.reference_no.split("MU-")
  
          this.icasCtrl=icasCtrl[1];
        
         this.institutionForm.controls.icasCtrl.setValue(this.icasCtrl);
        }
        else if(this.info_type==='IQAS'){
        //  this.institutionForm.controls.iqasCtrl.patchValue(this.institute.reference_no);
          const iqasCtrl=this.institute.reference_no.split("MU-")
          this.iqasCtrl =iqasCtrl[1];
         this.institutionForm.controls.iqasCtrl.setValue(this.iqasCtrl);
        }
        else if(this.info_type==='CES'){
      //    this.institutionForm.controls.cesCtrl.patchValue(this.institute.reference_no);
          const cesCtrl=this.institute.reference_no.split("MU-")
          this.cesCtrl= cesCtrl[1];
         this.institutionForm.controls.cesCtrl.setValue(this.cesCtrl);
        }
        else if(this.info_type==='MYIEE'){
       //   this.institutionForm.controls.myieeCtrl.patchValue(this.institute.reference_no);
          const myieeCtrl=this.institute.reference_no.split("MU-")
          
          this.myieeCtrl=myieeCtrl[1];
         
         this.institutionForm.controls.myieeCtrl.setValue(this.myieeCtrl);
         
         
  
        }
        else if(this.info_type==='ICES'){
      //    this.institutionForm.controls.icesCtrl.patchValue(this.institute.reference_no);
          const icesCtrl=this.institute.reference_no.split("MU-")
          
          this.icesCtrl=icesCtrl[1];
         
         this.institutionForm.controls.icesCtrl.setValue(this.icesCtrl);
         
         
  
        }
        else if(this.info_type==='NASBA'){
    //      this.institutionForm.controls.nasbaCtrl.patchValue(this.institute.reference_no);
          const nasbaCtrl=this.institute.reference_no.split("MU-")
          
          this.nasbaCtrl=nasbaCtrl[1];
         
         this.institutionForm.controls.nasbaCtrl.setValue(this.nasbaCtrl);
         
         
  
        }
        else if(this.info_type==='NCEES'){
     //     this.institutionForm.controls.nceesCtrl.patchValue(this.institute.reference_no);
          const nceesCtrl=this.institute.reference_no.split("MU-")
          
          this.nceesCtrl=nceesCtrl[1];
         
         this.institutionForm.controls.nceesCtrl.setValue(this.nceesCtrl);
         
         
  
        }
        else if(this.info_type==='NARIC'){
  //        this.institutionForm.controls.naricCtrl.patchValue(this.institute.reference_no);
          const naricCtrl=this.institute.reference_no.split("MU-")
       this.naricCtrl=naricCtrl[1];
        this.institutionForm.controls.naricCtrl.setValue(this.naricCtrl);
         
         
  
        }
        else if(this.info_type==='National Committee on Accreditation'){
      //  this.institutionForm.controls.ncaCtrl.patchValue(this.institute.reference_no);
          const ncaCtrl=this.institute.reference_no.split("MU-")
          this.ncaCtrl=ncaCtrl[1];
         this.institutionForm.controls.ncaCtrl.setValue(this.ncaCtrl);
        }  else if(this.info_type==='visa'){
          this.institutionForm.controls.visaRefCtrl.patchValue(this.institute.reference_no);
          
        }
        else if(this.info_type==='employment'){
           this.institutionForm.controls.empRefCtrl.patchValue(this.institute.reference_no);       

        }
        else if(this.info_type==='study'){
          this.institutionForm.controls.studyRefCtrl.patchValue(this.institute.reference_no);       

       }
       else if(this.info_type==='others'){
        this.institutionForm.controls.otherAccCtrl.patchValue(this.institute.reference_no);       

     }
      
    }

    this.api.suggestInstitute().subscribe((data)=>{
      this.universitynames = data['data'];
    })
  this.SHOW= true;
  }

  dismiss() {
    this.ref.close();
  }

  saveInstitution(){
    if(this.wesTrue){
    
            this.institutionForm.controls.wesIdCtrl.markAsDirty();
            this.institutionForm.controls.emailAsWesCtrl.markAsDirty();
            this.institutionForm.controls.nameaswesctrl.markAsDirty();
            this.institutionForm.controls.lastnameaswesctrl.markAsDirty();
       
    }
    else if(this.eduPerTrue){
      this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.eduEmailCtrl.markAsDirty();
      this.institutionForm.controls.countryCtrl.markAsDirty();
      this.institutionForm.controls.contactCtrl.markAsDirty();
      this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.eduPerCtrl.markAsDirty();
    }
   else if(this.cesTrue){
      // this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.cesEmailCtrl.markAsDirty();
      // this.institutionForm.controls.countryCtrl.markAsDirty();
      // this.institutionForm.controls.contactCtrl.markAsDirty();
      // this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.cesCtrl.markAsDirty();
    }
    else if(this.nasbaTrue){
      // this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.nasbaCtrl.markAsDirty();
      // this.institutionForm.controls.countryCtrl.markAsDirty();
      // this.institutionForm.controls.contactCtrl.markAsDirty();
      // this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.nasbaEmailCtrl.markAsDirty();
    }
    else if(this.iqasTrue){
      //this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.iqasEmailCtrl.markAsDirty();
      // this.institutionForm.controls.countryCtrl.markAsDirty();
      // this.institutionForm.controls.contactCtrl.markAsDirty();
      // this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.iqasCtrl.markAsDirty();
    }
    else if(this.nceesTrue){
      //this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.nceesEmailCtrl.markAsDirty();
      // this.institutionForm.controls.countryCtrl.markAsDirty();
      // this.institutionForm.controls.contactCtrl.markAsDirty();
      // this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.nceesCtrl.markAsDirty();
    }
    else if(this.ncaTrue){
      //this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.ncaEmailCtrl.markAsDirty();
      // this.institutionForm.controls.countryCtrl.markAsDirty();
      // this.institutionForm.controls.contactCtrl.markAsDirty();
      // this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.ncaCtrl.markAsDirty();
    }
    else if(this.naricTrue){
      //this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.naricEmailCtrl.markAsDirty();
      // this.institutionForm.controls.countryCtrl.markAsDirty();
      // this.institutionForm.controls.contactCtrl.markAsDirty();
      // this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.naricCtrl.markAsDirty();
    }
    else if(this.icesTrue){
      // this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.icesEmailCtrl.markAsDirty();
      // this.institutionForm.controls.countryCtrl.markAsDirty();
      // this.institutionForm.controls.contactCtrl.markAsDirty();
      // this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.icesCtrl.markAsDirty();
    }
    else if(this.studyTrue){
      this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.studyEmailCtrl.markAsDirty();
      this.institutionForm.controls.countryCtrl.markAsDirty();
      this.institutionForm.controls.contactCtrl.markAsDirty();
      this.institutionForm.controls.contactpersonCtrl.markAsDirty();
    }
    else if(this.employment){
      this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.empEmailCtrl.markAsDirty();
      this.institutionForm.controls.countryCtrl.markAsDirty();
      this.institutionForm.controls.contactCtrl.markAsDirty();
      this.institutionForm.controls.contactpersonCtrl.markAsDirty();
    }
    else if(this.visaTrue){
      this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.visaEmailCtrl.markAsDirty();
      this.institutionForm.controls.countryCtrl.markAsDirty();
      this.institutionForm.controls.contactCtrl.markAsDirty();
      this.institutionForm.controls.contactpersonCtrl.markAsDirty();
    }
    else if(this.icasTrue){
     // this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.icasEmailCtrl.markAsDirty();
      // this.institutionForm.controls.countryCtrl.markAsDirty();
      // this.institutionForm.controls.contactCtrl.markAsDirty();
      // this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.icasCtrl.markAsDirty();
    }
    else if(this.myieeTrue){
      // this.institutionForm.controls.universityNameCtrl.markAsDirty();
      this.institutionForm.controls.myieeEmailCtrl.markAsDirty();
      // this.institutionForm.controls.countryCtrl.markAsDirty();
      // this.institutionForm.controls.contactCtrl.markAsDirty();
      // this.institutionForm.controls.contactpersonCtrl.markAsDirty();
      this.institutionForm.controls.myieeCtrl.markAsDirty();
    }
    else{
      this.institutionForm.controls.universityNameCtrl.markAsDirty();
      // this.institutionForm.controls.emailCtrl.markAsDirty();
      this.institutionForm.controls.countryCtrl.markAsDirty();
      this.institutionForm.controls.contactCtrl.markAsDirty();
      this.institutionForm.controls.contactpersonCtrl.markAsDirty();
    }
    if(this.function_type == 'add'){
    if(this.type == 'edit'){
      if(this.info_type=="Educational credential evaluators WES"){
        this.api.getwesdetails(this.institutionForm.controls.wesIdCtrl.value,this.institutionForm.controls.emailAsWesCtrl.value,this.institutionForm.controls.nameaswesctrl.value,this.institutionForm.controls.lastnameaswesctrl.value).subscribe((data)=>{
              
          if(data['status'] == 400){
            this.toasterService.danger(data['message'],'Error',{duration: 3000});
          }else{
            this.institutionForm.controls.wesIdCtrl.markAsDirty();
            this.institutionForm.controls.emailAsWesCtrl.markAsDirty();
            this.institutionForm.controls.nameaswesctrl.markAsDirty();
            this.institutionForm.controls.lastnameaswesctrl.markAsDirty();
            if(this.institutionForm.valid){
              var emailAsWesValue = this.institutionForm.controls.emailAsWesCtrl.value;
               if(this.institutionForm.controls.emailAsWesCtrl.value == 'submit@wes.org' || this.institutionForm.controls.emailAsWesCtrl.value == 'contactca@wes.org' || emailAsWesValue.includes("@wes.org")){
                 this.show_edit_error = true;
               }else{
                 var ref_no = "MU-"+this.institutionForm.controls.wesIdCtrl.value
                // this.institutionForm.controls.wesIdCtrl.setValue("MU-"+''+this.institutionForm.controls.wesIdCtrl.value)
                 this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, '', '','','','','','','',
                 ref_no,'','','','','','','','','','','','','','','','','',this.institutionForm.controls.nameaswesctrl.value,this.institutionForm.controls.lastnameaswesctrl.value,this.institutionForm.controls.emailAsWesCtrl.value).subscribe(data => {              if(data['status'] == 200){
                     this.ref.close();
                   }else if(data['status'] == 400){
                   }     
                 });
               }
             }
          }  
        })
      }
      else if(this.info_type=="ICAS"){
        if(this.institutionForm.valid){
          var ref_no = "MU-"+this.institutionForm.controls.icasCtrl.value
       // this.institutionForm.controls.icasCtrl.setValue("MU-"+''+this.institutionForm.controls.icasCtrl.value)
        this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, '', this.institutionForm.controls.icasEmailCtrl.value,'','','','','','',
        '','','','', ref_no,'','','','','','','','','','','','','','','','').subscribe(data => {
         if(data['status'] == 200){
           this.ref.close();
         }else if(data['status'] == 400){
         }     
     });
     }
    }
     else if(this.info_type=="NCEES"){
      var ref_no = "MU-"+this.institutionForm.controls.nceesCtrl.value
    //  this.institutionForm.controls.nceesCtrl.setValue("MU-"+''+this.institutionForm.controls.nceesCtrl.value)
      this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, '', this.institutionForm.controls.nceesEmailCtrl.value,'','','','','','',
      '','','','','','','','','','','','','','','', ref_no,'','','','','').subscribe(data => {
       if(data['status'] == 200){
         this.ref.close();
       }else if(data['status'] == 400){
       }     
   });
   }
   else if(this.info_type=="NARIC"){
    var ref_no = "MU-"+this.institutionForm.controls.naricCtrl.value
    //this.institutionForm.controls.naricCtrl.setValue("MU-"+''+this.institutionForm.controls.naricCtrl.value)
    this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, '', this.institutionForm.controls.naricEmailCtrl.value,'','','','','','',
    '','','','','','','','','','','','','','','','',ref_no,'','','','').subscribe(data => {
     if(data['status'] == 200){
       this.ref.close();
     }else if(data['status'] == 400){
     }     
  });
  }
     else if(this.info_type=="CES"){
      var ref_no = "MU-"+this.institutionForm.controls.cesCtrl.value
     // this.institutionForm.controls.cesCtrl.setValue("MU-"+''+this.institutionForm.controls.cesCtrl.value)
     this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type,'', this.institutionForm.controls.cesEmailCtrl.value,'','','','','','',
     '','', ref_no,'','','','','','','','','','','','','','','','','','').subscribe(data => {
       if(data['status'] == 200){
         this.ref.close();
       }else if(data['status'] == 400){
       }     
   });
   }
   else if(this.info_type=="Educational Perspective"){
    var ref_no = "MU-"+this.institutionForm.controls.eduPerCtrl.value
    //this.institutionForm.controls.eduPerCtrl.setValue("MU-"+''+this.institutionForm.controls.eduPerCtrl.value)
   this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, '', this.institutionForm.controls.eduEmailCtrl.value,'','','','','','',
   '','', '',ref_no,'','','','','','','','','','','','','','','','','').subscribe(data => {
     if(data['status'] == 200){
       this.ref.close();
     }else if(data['status'] == 400){
     }     
  });
  }
  else if(this.info_type=="IQAS"){
    var ref_no = "MU-"+this.institutionForm.controls.iqasCtrl.value
   // this.institutionForm.controls.iqasCtrl.setValue("MU-"+''+this.institutionForm.controls.iqasCtrl.value)
    this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type,'',this.institutionForm.controls.iqasEmailCtrl.value,'','','','','','',
    '',ref_no, '','','','','','','','','','','','','','','','','','','').subscribe(data => {
     if(data['status'] == 200){
       this.ref.close();
     }else if(data['status'] == 400){
     }     
  });
  }
      else if(this.info_type=="University Details"){
        this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.studyEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,'','','',
        '','','','','',this.institutionForm.controls.studyRefCtrl.value,'','','','','','','','','','','','','','','').subscribe(data => {
          if(data['status'] == 200){
            this.ref.close();
          }else if(data['status'] == 400){
          }     
      });
      }
  
      else if(this.info_type=="Counsel"){
        this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.visaEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,'','','',
        '','','','','','','',this.institutionForm.controls.visaRefCtrl.value,'','',this.institutionForm.controls.visaAccCtrl.value,'','','','','','','','','','').subscribe(data => {
          if(data['status'] == 200){
            this.ref.close();
          }else if(data['status'] == 400){
          }     
      });
      }
      else if(this.info_type=="Company"){
        this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.empEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,'','','',
        '','','','','','',this.institutionForm.controls.empRefCtrl.value,'','',this.institutionForm.controls.empAccCtrl.value,'','','','','','','','','','','').subscribe(data => {
          if(data['status'] == 200){
            this.ref.close();
          }else if(data['status'] == 400){
          }     
      });
      }else if(this.info_type=="MYIEE"){
        var ref_no = "MU-"+this.institutionForm.controls.myieeCtrl.value
        //this.institutionForm.controls.myieeCtrl.setValue("MU-"+''+this.institutionForm.controls.myieeCtrl.value)
        this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, '',this.institutionForm.controls.myieeEmailCtrl.value,'','','','','','',
        '','','','','','','','','','','','',ref_no,'','','','','','','','').subscribe(data => {
          if(data['status'] == 200){
            this.ref.close();
          }else if(data['status'] == 400){
          }     
      });
      }
      else if(this.info_type=="ICES"){
        var ref_no = "MU-"+this.institutionForm.controls.icesCtrl.value
        //this.institutionForm.controls.icesCtrl.setValue("MU-"+''+this.institutionForm.controls.icesCtrl.value)
        this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type,'', this.institutionForm.controls.icesEmailCtrl.value,'','','','','','',
        '','','','','','','','','','','','','',ref_no,'','','','','','','').subscribe(data => {
         if(data['status'] == 200){
           this.ref.close();
         }else if(data['status'] == 400){
         }     
     });
     }
     else if(this.info_type=="NASBA"){
      var ref_no = "MU-"+this.institutionForm.controls.nasbaCtrl.value
     // this.institutionForm.controls.nasbaCtrl.setValue("MU-"+''+this.institutionForm.controls.nasbaCtrl.value)
      this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type,'', this.institutionForm.controls.nasbaEmailCtrl.value,'','','','','','',
      '','','','','','','','','','','','','','',ref_no,'','','','','','').subscribe(data => {
       if(data['status'] == 200){
         this.ref.close();
       }else if(data['status'] == 400){
       }     
   });
   }
   else if(this.info_type=="National Committee on Accreditation"){
    var ref_no = "MU-"+this.institutionForm.controls.ncaCtrl.value
    //this.institutionForm.controls.ncaCtrl.setValue("MU-"+''+this.institutionForm.controls.ncaCtrl.value)
    this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type,'', this.institutionForm.controls.ncaEmailCtrl.value,'','','','','','',
    '','','','','','','','','','','','','','','','','',ref_no,'','','').subscribe(data => {
     if(data['status'] == 200){
       this.ref.close();
     }else if(data['status'] == 400){
     }     
  });
  }
      else{
        this.api.updateInstituteDataByAdmin(this.institute.id,this.save_type, this.institutionForm.controls.universityNameCtrl.value, this.institutionForm.controls.otherEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,'','','',
        '','','','','','','','','','','',this.institutionForm.controls.otherAccCtrl.value,'','','','','','','','','').subscribe(data => {
          if(data['status'] == 200){
            this.ref.close();
          }else if(data['status'] == 400){
          }     
      });
      }
     }else{
      if(this.institutionForm.valid){
        if(this.wesTrue){
         var emailAsWesValue = this.institutionForm.controls.emailAsWesCtrl.value;
          this.api.getwesdetails(this.institutionForm.controls.wesIdCtrl.value,this.institutionForm.controls.emailAsWesCtrl.value,this.institutionForm.controls.nameaswesctrl.value,this.institutionForm.controls.lastnameaswesctrl.value).subscribe((data)=>{
              if(data['status'] == 400){
                this.toasterService.danger(data['message'],'Error',{duration: 3000});
              }else{
                  if(this.institutionForm.controls.emailAsWesCtrl.value == 'submit@wes.org' || this.institutionForm.controls.emailAsWesCtrl.value == 'contactca@wes.org' || emailAsWesValue.includes("@wes.org")){
                 this.show_edit_error = true;
          }else{
            var ref_no = "MU-"+this.institutionForm.controls.wesIdCtrl.value
            //this.institutionForm.controls.wesIdCtrl.setValue("MU-"+''+this.institutionForm.controls.wesIdCtrl.value)
            this.api.createInstitute('','','','','',this.save_type,'','','',ref_no,'','','','','',
            '','','','','','','','','','','','',this.institutionForm.controls.nameaswesctrl.value,this.institutionForm.controls.lastnameaswesctrl.value,this.institutionForm.controls.emailAsWesCtrl.value)
            .subscribe(data => {
              if(data['status'] == 200){
                this.ref.close();
              }else if(data['status'] == 400){
              }  
            });
          } 
              }
          })
        }
        else if(this.cesTrue){
          var ref_no = "MU-"+this.institutionForm.controls.cesCtrl.value
         // this.institutionForm.controls.cesCtrl.setValue("MU-"+''+this.institutionForm.controls.cesCtrl.value)
          this.api.createInstitute('',this.institutionForm.controls.cesEmailCtrl.value,'','','',this.save_type,'','','','','',ref_no,'','','','','','','','','','','','','','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.nceesTrue){
          var ref_no = "MU-"+this.institutionForm.controls.nceesCtrl.value
         // this.institutionForm.controls.nceesCtrl.setValue("MU-"+''+this.institutionForm.controls.nceesCtrl.value)
          this.api.createInstitute('',this.institutionForm.controls.nceesEmailCtrl.value,'','','',this.save_type,'','','','','','','','','','','','','','','','','','',ref_no,'','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.naricTrue){
          var ref_no = "MU-"+this.institutionForm.controls.naricCtrl.value
          //this.institutionForm.controls.naricCtrl.setValue("MU-"+''+this.institutionForm.controls.naricCtrl.value)
          this.api.createInstitute('',this.institutionForm.controls.naricEmailCtrl.value,'','','',this.save_type,'','','','','','','','','','','','','','','','','','','',ref_no,'','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.nasbaTrue){
          var ref_no = "MU-"+this.institutionForm.controls.nasbaCtrl.value
          //this.institutionForm.controls.nasbaCtrl.setValue("MU-"+''+this.institutionForm.controls.nasbaCtrl.value)
          this.api.createInstitute('',this.institutionForm.controls.nasbaEmailCtrl.value,'','','',this.save_type,'','','','','','','','','','','','','','','','','',ref_no,'','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.eduPerTrue){
          var ref_no = "MU-"+this.institutionForm.controls.eduPerCtrl.value
        //  this.institutionForm.controls.eduPerCtrl.setValue("MU-"+''+this.institutionForm.controls.eduPerCtrl.value)
          this.api.createInstitute(this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.eduEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,this.save_type,'','','','','','',ref_no,'','','','','','','','','','','','','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.icasTrue){
          var ref_no = "MU-"+this.institutionForm.controls.icasCtrl.value
          //this.institutionForm.controls.icasCtrl.setValue("MU-"+''+this.institutionForm.controls.icasCtrl.value)
          this.api.createInstitute('',this.institutionForm.controls.icasEmailCtrl.value,'','','',this.save_type,'','','','','','','',ref_no,'','','','','','','','','','','','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.iqasTrue){
          var ref_no = "MU-"+this.institutionForm.controls.iqasCtrl.value
       //   this.institutionForm.controls.iqasCtrl.setValue("MU-"+''+this.institutionForm.controls.iqasCtrl.value)
          this.api.createInstitute('',this.institutionForm.controls.iqasEmailCtrl.value,'','','',this.save_type,'','','','',ref_no,'','','','','','','','','','','','','','','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.studyTrue){
          this.api.createInstitute(this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.studyEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,this.save_type,'','','','','','','','',this.institutionForm.controls.studyRefCtrl.value,'','','','','','','','','','','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.employment){
          this.api.createInstitute(this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.empEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,this.save_type,'','','','','','','','','',this.institutionForm.controls.empRefCtrl.value,'','',this.institutionForm.controls.empAccCtrl.value,'','','','','','','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.visaTrue){
          this.api.createInstitute(this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.visaEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,this.save_type,'','','','','','','','','','',this.institutionForm.controls.visaRefCtrl.value,'','',this.institutionForm.controls.visaAccCtrl.value,'','','','','','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        } 
        else if(this.myieeTrue){
          var ref_no = "MU-"+this.institutionForm.controls.myieeCtrl.value
         // this.institutionForm.controls.myieeCtrl.setValue("MU-"+''+this.institutionForm.controls.myieeCtrl.value)
          this.api.createInstitute('',this.institutionForm.controls.myieeEmailCtrl.value,'','','',this.save_type,'','','','','','','','','','','','','','','',ref_no,'','','','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });

        }
        else if(this.icesTrue){
          var ref_no = "MU-"+this.institutionForm.controls.icesCtrl.value
        //  this.institutionForm.controls.icesCtrl.setValue("MU-"+''+this.institutionForm.controls.icesCtrl.value)
          this.api.createInstitute('',this.institutionForm.controls.icesEmailCtrl.value,'','','',this.save_type,'','','','','','','','','','','','','','','','',ref_no,'','','','','','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
        else if(this.ncaTrue){
          var ref_no = "MU-"+this.institutionForm.controls.ncaCtrl.value
         // this.institutionForm.controls.ncaCtrl.setValue("MU-"+''+this.institutionForm.controls.ncaCtrl.value)
          this.api.createInstitute('',this.institutionForm.controls.ncaEmailCtrl.value,'','','',this.save_type,'','','','','','','','','','','','','','','','','','','','',ref_no,'','','')
          .subscribe(data => {
            if(data['status'] == 200){
              this.ref.close();
            }else if(data['status'] == 400){
            }  
          });  
        }
      else{
        this.otheraccTrue =true;
        this.api.createInstitute(this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.otherEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,this.save_type,'','','','','','','','','','','','','','',this.institutionForm.controls.otherAccCtrl.value,'','','','','','','','','')
        .subscribe(data => {
          if(data['status'] == 200){
            this.ref.close();
          }else if(data['status'] == 400){
          }  
        });
        }
      }
     }
      

  }
    if(this.function_type == 'edit'){

     if(this.info_type=="Educational credential evaluators WES"){
      this.api.getwesdetails(this.institutionForm.controls.wesIdCtrl.value,this.institutionForm.controls.emailAsWesCtrl.value,this.institutionForm.controls.nameaswesctrl.value,this.institutionForm.controls.lastnameaswesctrl.value).subscribe((data)=>{

        if(data['status'] == 400){
          this.toasterService.danger(data['message'],'Error',{duration: 3000});
        }else{
          if(this.institutionForm.valid){
            var emailAsWesValue = this.institutionForm.controls.emailAsWesCtrl.value;
            if(this.institutionForm.controls.emailAsWesCtrl.value == 'submit@wes.org' || this.institutionForm.controls.emailAsWesCtrl.value == 'contactca@wes.org' || emailAsWesValue.includes("@wes.org")){
              this.show_edit_error = true;
            }else{
              var ref_no = "MU-"+this.institutionForm.controls.wesIdCtrl.value
             // this.institutionForm.controls.wesIdCtrl.setValue("MU-"+''+this.institutionForm.controls.wesIdCtrl.value)
              this.api.updateInstituteData(this.institute.id, '', '','','','','','','',
              ref_no,'','','','','','','','','','','','','','','','','',this.institutionForm.controls.nameaswesctrl.value,this.institutionForm.controls.lastnameaswesctrl.value, this.institutionForm.controls.emailAsWesCtrl.value).subscribe(data => {
                if(data['status'] == 200){
                  this.ref.close();
                }else if(data['status'] == 400){
                }     
              });
            }
          }
        }
      })  
    }
    else if(this.info_type=="ICAS"){
      if(this.institutionForm.valid){
        var ref_no = "MU-"+this.institutionForm.controls.icasCtrl.value
      // this.institutionForm.controls.icasCtrl.setValue("MU-"+''+this.institutionForm.controls.icasCtrl.value)
      this.api.updateInstituteData(this.institute.id, '', this.institutionForm.controls.icasEmailCtrl.value,'','','','','','',
      '','','','', ref_no,'','','','','','','','','','','','','','','','').subscribe(data => {
       if(data['status'] == 200){
         this.ref.close();
       }else if(data['status'] == 400){
       }     
   });
   }
  }
   else if(this.info_type=="NCEES"){
    if(this.institutionForm.valid){
      var ref_no = "MU-"+this.institutionForm.controls.nceesCtrl.value
   // this.institutionForm.controls.nceesCtrl.setValue("MU-"+''+this.institutionForm.controls.nceesCtrl.value)
    this.api.updateInstituteData(this.institute.id, '', this.institutionForm.controls.nceesEmailCtrl.value,'','','','','','',
    '','','','','','','','','','','','','','','', ref_no,'','','','','').subscribe(data => {
     if(data['status'] == 200){
       this.ref.close();
     }else if(data['status'] == 400){
     }     
 });
 }
}
 else if(this.info_type=="NARIC"){
  if(this.institutionForm.valid){
    var ref_no = "MU-"+this.institutionForm.controls.naricCtrl.value
  //this.institutionForm.controls.naricCtrl.setValue("MU-"+''+this.institutionForm.controls.naricCtrl.value)
  this.api.updateInstituteData(this.institute.id, '', this.institutionForm.controls.naricEmailCtrl.value,'','','','','','',
  '','','','','','','','','','','','','','','','', ref_no,'','','','').subscribe(data => {
   if(data['status'] == 200){
     this.ref.close();
   }else if(data['status'] == 400){
   }     
});
}
 }
   else if(this.info_type=="CES"){
    if(this.institutionForm.valid){
      var ref_no = "MU-"+this.institutionForm.controls.cesCtrl.value
  //  this.institutionForm.controls.cesCtrl.setValue("MU-"+''+this.institutionForm.controls.cesCtrl.value)
   this.api.updateInstituteData(this.institute.id,'', this.institutionForm.controls.cesEmailCtrl.value,'','','','','','',
   '','', ref_no,'','','','','','','','','','','','','','','','','','').subscribe(data => {
     if(data['status'] == 200){
       this.ref.close();
     }else if(data['status'] == 400){
     }     
 });
 }
}
 else if(this.info_type=="Educational Perspective"){
  var ref_no = "MU-"+this.institutionForm.controls.eduPerCtrl.value
 // this.institutionForm.controls.eduPerCtrl.setValue("MU-"+''+this.institutionForm.controls.eduPerCtrl.value)
 this.api.updateInstituteData(this.institute.id, '', this.institutionForm.controls.eduEmailCtrl.value,'','','','','','',
 '','', '',ref_no,'','','','','','','','','','','','','','','','','').subscribe(data => {
   if(data['status'] == 200){
     this.ref.close();
   }else if(data['status'] == 400){
   }     
});
}
else if(this.info_type=="IQAS"){
  if(this.institutionForm.valid){
    var ref_no = "MU-"+this.institutionForm.controls.iqasCtrl.value
//  this.institutionForm.controls.iqasCtrl.setValue("MU-"+''+this.institutionForm.controls.iqasCtrl.value)
  this.api.updateInstituteData(this.institute.id,'',this.institutionForm.controls.iqasEmailCtrl.value,'','','','','','',
  '',ref_no, '','','','','','','','','','','','','','','','','','','').subscribe(data => {
   if(data['status'] == 200){
     this.ref.close();
   }else if(data['status'] == 400){
   }     
});
}
}
    else if(this.info_type=="study"){
      this.api.updateInstituteData(this.institute.id, this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.studyEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,'','','',
      '','','','','',this.institutionForm.controls.studyRefCtrl.value,'','','','','','','','','','','','','','','').subscribe(data => {
        if(data['status'] == 200){
          this.ref.close();
        }else if(data['status'] == 400){
        }     
    });
    }

    else if(this.info_type=="visa"){
      this.api.updateInstituteData(this.institute.id, this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.visaEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,'','','',
      '','','','','','','',this.institutionForm.controls.visaRefCtrl.value,'','',this.institutionForm.controls.visaAccCtrl.value,'','','','','','','','','','').subscribe(data => {
        if(data['status'] == 200){
          this.ref.close();
        }else if(data['status'] == 400){
        }     
    });
    }
    else if(this.info_type=="employment"){
      this.api.updateInstituteData(this.institute.id, this.institutionForm.controls.universityNameCtrl.value,this.institutionForm.controls.empEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,'','','',
      '','','','','','',this.institutionForm.controls.empRefCtrl.value,'','',this.institutionForm.controls.empAccCtrl.value,'','','','','','','','','','','').subscribe(data => {
        if(data['status'] == 200){
          this.ref.close();
        }else if(data['status'] == 400){
        }     
    });
    }else if(this.info_type=="MYIEE"){
      if(this.institutionForm.valid){
        var ref_no = "MU-"+this.institutionForm.controls.myieeCtrl.value
      //this.institutionForm.controls.myieeCtrl.setValue("MU-"+''+this.institutionForm.controls.myieeCtrl.value)
      this.api.updateInstituteData(this.institute.id, '',this.institutionForm.controls.myieeEmailCtrl.value,'','','','','','',
      '','','','','','','','','','','','',ref_no,'','','','','','','','').subscribe(data => {
        if(data['status'] == 200){
          this.ref.close();
        }else if(data['status'] == 400){
        }     
    });
    }
  }
    else if(this.info_type=="ICES"){
      if(this.institutionForm.valid){
        var ref_no = "MU-"+this.institutionForm.controls.icesCtrl.value
      //this.institutionForm.controls.icesCtrl.setValue("MU-"+''+this.institutionForm.controls.icesCtrl.value)
      this.api.updateInstituteData(this.institute.id,'', this.institutionForm.controls.icesEmailCtrl.value,'','','','','','',
      '','','','','','','','','','','','','',ref_no,'','','','','','','').subscribe(data => {
       if(data['status'] == 200){
         this.ref.close();
       }else if(data['status'] == 400){
       }     
   });
   }
  }
   else if(this.info_type=="NASBA"){
    if(this.institutionForm.valid){
      var ref_no = "MU-"+this.institutionForm.controls.nasbaCtrl.value
   // this.institutionForm.controls.nasbaCtrl.setValue("MU-"+''+this.institutionForm.controls.nasbaCtrl.value)
    this.api.updateInstituteData(this.institute.id,'', this.institutionForm.controls.nasbaEmailCtrl.value,'','','','','','',
    '','','','','','','','','','','','','','',ref_no,'','','','','','').subscribe(data => {
     if(data['status'] == 200){
       this.ref.close();
     }else if(data['status'] == 400){
     }     
 });
 }
}
 else if(this.info_type=="National Committee on Accreditation"){
  if(this.institutionForm.valid){
    var ref_no = "MU-"+this.institutionForm.controls.ncaCtrl.value
 //  this.institutionForm.controls.ncaCtrl.setValue("MU-"+''+this.institutionForm.controls.ncaCtrl.value)
  this.api.updateInstituteData(this.institute.id,'', this.institutionForm.controls.ncaEmailCtrl.value,'','','','','','',
  '','','','','','','','','','','','','','','','','',ref_no,'','','').subscribe(data => {
   if(data['status'] == 200){
     this.ref.close();
   }else if(data['status'] == 400){
   }     
});
}
 } else{
      this.api.updateInstituteData(this.institute.id, this.institutionForm.controls.universityNameCtrl.value, this.institutionForm.controls.otherEmailCtrl.value,this.institutionForm.controls.countryCtrl.value,this.institutionForm.controls.contactCtrl.value,this.institutionForm.controls.contactpersonCtrl.value,'','','',
      '','','','','','','','','','','',this.institutionForm.controls.otherAccCtrl.value,'','','','','','','','','').subscribe(data => {
        if(data['status'] == 200){
          this.ref.close();
        }else if(data['status'] == 400){
        }     
    });
    }
     }
  }
  
  

  setValuesforName(value){
   this.api.suggestInstituteallDATA(value).subscribe((data)=>{
    // this.institutionForm.controls.emailCtrl.patchValue(data['data'][0]['email']) 
    this.institutionForm.controls.countryCtrl.patchValue(parseInt(data['data'][0]['country_name'],10)) 
    // this.institutionForm.controls.contactCtrl.patchValue(data['data'][0]['contact_number']) 
    // this.institutionForm.controls.contactpersonCtrl.patchValue(data['data'][0]['contact_person']) 

    data['data'].forEach(element => {
      
      if(element.email != null || element.email != undefined || element.email != ''){
        if ( this.EmailAutoS.indexOf(element.email) === -1) this.EmailAutoS.push(element.email);
      }
      // if(element.contact_number != null || element.contact_number != undefined || element.contact_number != ''){
      //   if ( this.contact_numberAutoS.indexOf(element.contact_number) === -1) this.contact_numberAutoS.push(element.contact_number);
      // }
      if(element.country_name != null || element.country_name != undefined || element.country_name != ''){
        if ( this.country_nameAutoS.indexOf(element.country_name) === -1) this.country_nameAutoS.push(element.country_name);
      }
      // if(element.contact_person != null || element.contact_person != undefined || element.contact_person != ''){
      //   if ( this.contact_personAutoS.indexOf(element.contact_person) === -1) this.contact_personAutoS.push(element.contact_person);
      // }

    });

    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;

  
  }



  onKey(event){

    if((event.target.value != undefined || event.target.value != null) && event.target.value.length > 0){

      this.showList = false
    }else{

      this.showList = true
    }
  }

  @HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
	  if(event.key == 'Enter'){
			if(this.institutionForm.valid  ){
				
        this.saveInstitution()
        this.errflag=false;
			}else{
       this.errflag=true
        
      }

   }
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../shared/api.service';
import { saveAs } from 'file-saver';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-acceptRejectCollege',
  templateUrl: './acceptRejectCollege.html',
  styleUrls: ['./acceptRejectCollege.scss']
})
export class acceptRejectCollege implements OnInit {
  app_id: any;
  college_id: any;
  result: any;
    notes: any;

  constructor(private route : ActivatedRoute,
    private toastrService: NbToastrService,
              protected api : ApiService) {
    this.app_id = this.route.snapshot.queryParamMap.get('app_id');
    this.college_id = this.route.snapshot.queryParamMap.get('college_id');
    this.result = this.route.snapshot.queryParamMap.get('result');
   }

    ngOnInit() {
        if(this.result == 'accept'){
            this.api.replyFromCollege(this.app_id,'',this.college_id,this.result).subscribe((data)=>{
                if(data['status'] == 200){
                    this.toastrService.show('Thank you','SUCCESS', {
                        duration :3000
                    });
                }
            });
        }
    }

    saveNotes(){
        this.api.replyFromCollege(this.app_id,this.notes,this.college_id,this.result).subscribe(data=>{
            if(data['status'] == 200){
                this.toastrService.show('Thank you','SUCCESS', {
                    duration :3000
                });
            }
        })
    }
}
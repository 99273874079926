import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/api.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NbAuthJWTToken,NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
@Component({
  selector: 'ngx-admin-otp',
  templateUrl: './admin-otp.component.html',
  styleUrls: ['./admin-otp.component.scss']
})
export class AdminOtpComponent {
  otp: any;
  alert: any;
  alertflag: number;
  user_id: any;
  enterOtp:any;
  otpValidation: boolean;
  // menuArray:string[];
  userRole:string;
  constructor(private router : Router,
    private route : ActivatedRoute,
    protected api : ApiService,
    private toastrService: NbToastrService,
    private authService : NbAuthService) {
      this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
          this.user_id=token.getPayload()['id'];
          this.otp=token.getPayload()['userOtp'];

          if(token.getPayload()['role'] == 'student'){
            this.router.navigate(['auth/logout'])
          }
      });
    }

  ngOnInit() {
    this.api.sendOtp().subscribe(data => {
      if (data['status'] == 200) {
        // this.otp = data['data']
        this.toastrService.success("Otp Send successfully", '', {
          duration: 3000
        })
      }
    })
  }
  resendOtp(){
    this.api.sendOtp().subscribe(data=>{
      if(data['status'] == 200){
        // this.otp = data['data']
        this.toastrService.success("Otp Re-Send successfully", '', {
          duration: 3000
        });
        this.authService.onTokenChange().subscribe(response => {
          this.otp=response.getPayload()['userOtp'];
          
        });
      }
    })
  }
  cancel(){
    this.router.navigate(['auth/logout']);
  }
  verify(){
    this.enterOtp = (document.getElementById('enterOtp') as HTMLInputElement).value;
    if(this.otp === this.enterOtp){  
        this.api.updateOtp().subscribe(data=>{
        // if(this.menuArray.includes('student-management')){
                this.api.postVerifiedOtp(this.user_id,true).subscribe((resp)=>{
                })
                this.router.navigate(['pages/help'])
              // }else{
              //   this.api.postVerifiedOtp(this.user_id,true).subscribe((resp)=>{
                  
              //   })
              //   this.router.navigate([`pages/${this.menuArray[0]}`])
              // }
        })       
    }else{
      this.toastrService.danger("Wrong OTP", '', {
        duration: 3000
      })
      this.otpValidation = false;
      
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewComponent } from './view.component';
import { SharedModule } from '../../pages/shared-authpipe.module';
import {TabViewModule} from 'primeng/tabview';
import {TableModule} from 'primeng/table';
import {NbCardModule ,NbPopoverModule, NbActionsModule, NbButtonModule,NbInputModule, NbAlertModule, NbSpinnerModule, NbStepperModule, NbAccordionModule} from '@nebular/theme'; 
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { NbSelectModule, NbDatepickerModule } from '@nebular/theme';
import { applndetails } from './applndetails';
import { AddAffiliationInstructionaldetails } from './AddInstitutionAffiliationDetails';
import { ConfirmationService, ConfirmDialogModule} from 'primeng/primeng';
import { MatStepperModule} from '@angular/material';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { UploadDocuments } from './uploadDocuments.component';
import { MatCheckboxModule,MatFormFieldModule } from '@angular/material';
import { changeClgNameComponent } from './changeClgName.component';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgxTinymceModule } from 'ngx-tinymce';

@NgModule({
  declarations: [ViewComponent,applndetails,AddAffiliationInstructionaldetails,UploadDocuments,changeClgNameComponent],
  imports: [
    MatAutocompleteModule,
    CommonModule,
    MatCheckboxModule,
    SharedModule,
    TabViewModule,
    TableModule,
    NbCardModule,
    NbPopoverModule,
    NbActionsModule,
    NbButtonModule,
    NbAlertModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    MessageModule,
    NbSpinnerModule,
    NbSelectModule, 
    NbDatepickerModule,
    MatStepperModule,
    NbStepperModule,
    NbAccordionModule,
    InputSwitchModule,
    ConfirmDialogModule,
    FileUploadModule,
    DialogModule,
    MatFormFieldModule,
    NbInputModule,
    NgxTinymceModule.forRoot({
      // baseURL: './assets/tinymce/',
       // or cdn
       baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/'
     }),
  ],
  entryComponents : [
    applndetails,
    AddAffiliationInstructionaldetails,
    UploadDocuments
  ],
  providers: [ConfirmationService]
})
export class ViewModule { }